@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600&display=swap');

         body{
         padding: 0px;
         margin: 0px;
         font-family: 'Barlow', sans-serif;
         }
         .image_cls{
         width: 100%;
         float: left;
         height: calc(100vh - 0px);
         background: #005BF0;
         }
         .image_cls img{
         width: 100%;
         border-radius: 0px;

         }
         .container_fluid_1{
         width: 100%;
         float: left;
         }
         .container_1{
         width: 100%;
         float: left;
         }
         .container_1 .row_1{
         width: 100%;
         float: left;
         }
         .container_1 .row_1 .col-sm_1{
         width: 50%;
         float: left;
         position: relative;
         }
         .logo_img_1{
         width: 40%;
         float: left;
         padding: 10% 0 2% 10%;
         }
         .content_start{
         width: 100%;
         float: left;
         padding: 10px 0 10px 10%;
         margin: 3% 0 0% 0px;
         background: #eee;
         font-size: 1.2em;
         color: #6c6c6c;
         }
         .content_start_lists{
         width: 96%;
         float: left;
         margin: 20px 0;
         padding: 4% 0 0 4%;
         }
         input[type="button"] {
         margin-top: 20px;
         }
         .node {
         height: 10px;
         width: 10px;
         border-radius: 50%;
         display:inline-block;
         transition: all 1000ms ease;
         }
         .activated {
         box-shadow: 0px 0px 3px 2px rgba(0, 91, 240, 0.8);
         }
         .divider {
         height: 40px;
         width: 2px;
         margin-left: 4px;
         transition: all 800ms ease;
         }
         li p {
         display:inline-block;
         margin-left: 25px;
         }
         li {
         list-style: none;
         line-height: 1px;
         }
         .blue { background-color: #005BF0 }
         .green{ background-color: rgba(92, 184, 92, 1) }
         .red { background-color: rgba(255, 148, 148, 1); }
         .grey { background-color: rgba(201, 201, 201, 1); }
         
         #progress{
         width: 78%;
         float: left;
         }
         #progress li{
         width: 100%;
         float: left;
         opacity: 0.34;
         }
         #progress li p{
         color: #000;
         }
         .creat_account{
         float: left;
         font-weight: 500;
         background: #005BF0;
         display: inline-block;
         height: auto;
         line-height: initial;
         color: #fff;
         text-decoration: none;
         margin: 4% 0 0 6%;
         padding: 10px 20px;
         border-radius: 2px;
         font-size: 1em;
         }
         .creat_account_link{
         color: #005BF0;
         display: inline-block;
         height: auto;
         line-height: initial;
         text-decoration: none;
         margin: 1% 0 0 6%;
         padding: 10px 0px 0px 0px;
         border-radius: 4px;
         font-size: .9em;
         }
         #progress li.active{
         opacity: 1;
         }
         .text_animation_sortd {
		    position: absolute;
		    top:6%;
		    left: 10%;
		}


         .wrapper{
         width: 100%;
         float: left;
         display: inline-block;
         }
         .wrapper .static-txt{
         color: #fff;
         font-size: 60px;
         font-weight: 500;
         }
         .wrapper .dynamic-txts{
            height: 80px;
            line-height: 80px;
            overflow: hidden;
            padding: 0px;
            margin: -16px 0 0 0;
         }
         .dynamic-txts li{
         list-style: none;
         color: #ffcc00;
         font-size: 60px;
         font-weight: 500;
         position: relative;
         top: 0;
         animation: slide 12s steps(4) infinite;
         }
         @keyframes slide {
         100%{
         top: -360px;
         }
         }
         .dynamic-txts li span{
         position: relative;
         margin: 5px 0;
         line-height: 90px;
         }
         .dynamic-txts li span::after{
         content: "";
         position: absolute;
         left: 0;
         height: 100%;
         width: 100%;
         background: #005BF0;
         border-left: 2px solid #ffcc00;
         animation: typing 3s steps(10) infinite;
         }
         @keyframes typing {
         40%, 60%{
         left: calc(100% + 30px);
         }
         100%{
         left: 0;
         }
         }
         .form_div{
         width: 80%;
         float: left;
         position: relative;
         top: 5%;
         margin:0 10%;
         }
         .btn-info {
            background-color: #005BF0 !important;
         }
         .card-signup .form-check a{
            color: #005BF0 !important;
            text-decoration: none;
         }
         .copy_license_details{
            width: 90%;
            float: left;
            text-align: left;
            min-height: 80vh;
            padding: 5% 0;
         }
         .copy_license_details .success_text{
            color:#fff;
            padding: 20px 0 20px 0;
            font-size: 17px;
         }
         .copy_license_details .copy_clipboard{
            width: 110px;
            padding: 10px 18%;
            border-radius: 4px;
            box-shadow: 0px 2px 3px rgb(0 0 0 / 12%);
            display: inline-block;
         }
         .copy_license_details .copy_clipboard_final{
            background: linear-gradient(to right, #11998e, #38ef7d);
            border: none;
            color: #fff;
            font-size: 1.24em;
            font-weight: 600;
            padding: 10px 18%;
            border-radius: 4px;
            box-shadow: 0px 2px 3px rgb(0 0 0 / 12%);
            display: inline-block;
            margin: 25px 0 0 0;
         }
         .disabled_text_body{
            width: 100%;
            float: left;
            background: #fff;
            color: #626262;
            border-radius: 5px;
            margin: 20px 0 10px 0;
            padding: 20px;
            font-family: "Google Sans",sans-serif !important;
         }
         .disabled_text_body .disabled_text_body_access{
            width: 100%;
            float: left;
            padding: 0 0 10px 0;
         }
         .disabled_text_body .disabled_text_body_secret{
            width: 100%;
            float: left;
            padding: 0 0 10px 0;
         }
         .sortd_1_backtodash{
            width: calc(100% - 120px);
            float: left;
            color: #fff;
            margin-left:20px;
            border-radius: 4px;
         }
         /* .sortd_1_backtodash:hover{
            width: 90%;
    float: left;
    color: #fff;
    font-size: 17px !important;
    padding: 10px 0;
    text-decoration: none;
    font-weight: 500;
    border: 1px solid rgba(255,255,255,0.4);
    margin: 8% 5% 0%;
    border-radius: 4px;
         } */
         .copied_infact_message{
            width: auto;
            float: left;
            color: #fff;
            padding: 6px 20px;
            border-radius: 4px;
            position: relative;
            z-index: 9999;
            background: rgb(0,0,0,0.7);
            position: absolute;
            left: 0;
            bottom: 40px;
         }
         .click_heretologin{
            width: 100%;
            float: left;
            font-size: 16px;
         }
         #progress li.active div.grey{
            background-color: #ddd !important;
         }
         #progress li.active div.grey{
            background-color: #005BF0 !important;
         }
         #createProject .row{
           width: 137%;
           font-family: "Google Sans",sans-serif !important;
         }
         .name_ofbadge_span{
            margin: 10px 0 0 24px;
            display: inline-block;
         }
         .input-group-text{
            height: 50px;
            border-radius: 0px !important;
         }
         .form-check a{
            color: #005BF0;
            text-decoration: none;
            padding-bottom: 10px;
         }
         .bottom_formsection{
            width: 100%;
            float: left;
            text-align: center;
            border-top: 1px solid #eee;
            padding: 10px 0 0 0;
         }
         .bottom_formsection a{
            font-weight: 500;;
         }
         .terms_and_condition{
            color: #555;
         }
         .terms_and_condition a{
            color:#005BF0;
            text-decoration: none;
         }
         .social_icons_round{
            text-transform: capitalize !important;
            font-size: 14px !important;
            color: rgb(0, 0, 0) !important;
            width: 100% !important;
            font-weight: 500 !important;
            border: 1px solid rgb(204, 204, 204) !important;
            background: rgb(255, 255, 255) !important;
            display: inline-block;
            text-decoration: none;
            padding: 10px 0;
            border-radius: 30px;
         }
         .social_icons_round:hover{
            background: #f4f4f4 !important;
         }
         .only_form_changes{
            width: 100%;
            text-align: right;
            padding-bottom: 0px !important;
         }
         /* .extra_text_addedsign{
            font-size: 9px;
    padding: 20px 0px !important;
    width: 80%;
    margin: 0 10% -1%;
    color: #555;
         } */
         #createProject{
            padding: 50px 0 30px;
            border: 1px solid #ddd;
            border-radius: 10px;
            background:#f5f5f5;
         }
         .inbtw_formelements_label{
            color: #000 !important;
            font-size: 13px !important;
            display: inline-block;
            margin: 0 0 0 0;
            float: left;
            padding: 0 0 20px 0 !important;
         }
         .inbtw_formelements{
            text-align: left;
         }
         .extra_pading_buttonsplus{
            margin-top: 30px !important;
            background-color: #4cc984 !important;
            width: 84%;
         }
         .default_card_settingfoot{
            background: #f4f4f4 !important;
            padding: 10px 4% !important;
            text-align: left;
            margin-bottom: 10% !important;
         }
         .default_card_settingfoot a{
            color: #005BF0;
            text-decoration: none;
         }


         .heading_first {
            margin: -16px 0 0 6%;
            font-weight: 500;
            font-size: 17px;
            line-height: 28px;
        }
        .image_cls {
            width: 100%;
            float: left;
            height: 100vh;
            background-color: #005bf0;
            /* background-image: url(../../assets/img/latest-bg.png); */
            background-size: cover;
        }

        .creat_account:hover {
            background: #024bbe;
            color: #fff;
         }
        
        
         h3.header {
               font-size: 20px;
               margin-bottom: 10px;
         }
         .card-signup h4.card-title {
            font-size: 20px;
            margin-top: 14px;
        }
        .proCompGrn .node {
         background: #07b106 !important;
     }
     .proCompGrn .divider {
      background: #07b106 !important;
  }
  .btn-dfltPlug {
   background: transparent !important;
   border: 1px solid #fff !important;
   color: #fff !important;
   font-weight: 400 !important;
   font-family: 'Barlow';
   font-size: 16px !important;
   padding: 10px !important;
   text-align: left;
   text-transform: uppercase;
   float: left;
   text-decoration: none;
   width: calc(100% - 130px);
   position: relative;
}
.btn-dfltPlug i {
   padding-right: 10px;
}
.btn-dfltPlug b {
   position: absolute;
   right: 10px;
}
.timeline-body textarea.disabled_text_body {
   margin-bottom: 30px;
   border-radius: 15px
}
.btn-dfltPlug:hover {
   background: #fff !important;
   color: #333 !important;
   cursor: pointer;
}
.btn-dfltPlug:focus{
   outline: none;
}
.pn {
   opacity: 0.6;
}
.btn-round.extra_pading_buttonsplus.uncheckeddisabled.btn.btn-info.disabled {
    color: #fff;
    background: #51bcda !important;
}
.uncheckeddisabled:hover{
   cursor: not-allowed;
   background-color:#51bcda !important;
}
.uncheckeddisabled.btn:disabled, .uncheckeddisabled.btn[disabled], .uncheckeddisabled.btn.disabled{
   pointer-events: stroke !important;
}