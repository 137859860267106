@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600&display=swap');

body{
    font-family: 'Barlow', sans-serif !important;
}

.dp-box{
    padding: 0px 10px 10px 10px;
}
.themeBox {
    width: 100%;
    float: left;
    margin-bottom: 40px;
}
.mobDevice img {
    width: 100%;
}
#navbarDropdownMenuLink:after {
    display: none;
}
/* .user {
    background: #f1f1f1;
    margin-top: 0px !important;
    padding-top: 20px;
} */
/* .logo:after {
    display: none;
}
.user:after {
    display: none;
} */

/* .sidebar-wrapper .nav li.active a {
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li.active a i {
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li a i{
    font-size: 20px !important;
} */
/* .sidebar-wrapper .nav li:hover a{
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li:hover a i{
    color: #0660f0 !important;
}
.info a span {
    text-transform: uppercase;
    font-family: 'Barlow';
    font-weight: 500;
}
.minBtn button{
    background: #d4d3d3;
}
.minBtn button i {
    color: #7c7e82;
}
.minBtn button:hover, .minBtn button:focus{
    background: #0d61f0 !important;
}
.minBtn button:hover i, .minBtn button:focus i {
    color: #fff !important;
}
.logo-mini{
    display: none !important;
}
.sidebar-mini .logo-mini{
    display: block !important;
}
.logo-normal img {
    width: 120px;
    padding-left: 10px;
}

.themCard .form-check-label {
    width: 100%;
    float: left;
    padding: 20px;
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    font-family: 'Barlow';
    margin-bottom: 0px !important;
    position: relative;
    text-transform: capitalize;
    color: #0a72fc;
}
.themCard {
    background: #f9f9f9;
    width: 46.6%;
    float: left;
    margin: 0px 15px !important;
    box-sizing: border-box;
    border-radius: 10px;
    min-height: 360px;
    margin-bottom: 30px !important;
}
.themImg {
    width: 100%;
    float: left;
}
.themImg img {
    width: 29%;
    float: left;
    margin: 0px 2%;
    border: 2px solid #0a72fc;
    border-radius: 10px;
    box-shadow: 0px 1px 6px #b1b1b1;
}
.themh4 {
    font-size: 16px !important;
    text-align: left;
    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    color: #000;
    margin-top: 0px;
}
.themPge #createProject {
    background: transparent;
    border: none;
    padding: 0px;
    float: left;
    width: 100%;
} */
.consFom {
    width: 50%;
    float: left;
}

.consFom {
    width: 48%;
    float: left;
    margin: 0px 1%;
    text-align: left;
    margin-bottom: 10px;
}

.lblNme {
    font-size: 15px !important;
    color: #252422 !important;
}
/* .btmbar {
    width: 100%;
    position: absolute;
    height: 50px;
    display: block;
    bottom: 0;
    z-index: 1;
    background: #333;
    display: none;
} */
.inptFom .css-yk16xz-control{
    height: 40px !important;
}
.fulconsFom {
    width: 98%;
}
.inptFom textarea{
    width: 100%;
}

.inptFom textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #e3e3e3 !important;
    font-size: 14px !important;
    font-family: 'Barlow';
    padding: 10px;
}
/* .alrtBx {
    width: 100%;
    float: left;
    text-align: left;
} */
.copyBtn {
    position: absolute;
    top: -9px;
    right: 0px;
}
.copyBtn .copyTxt {
    background: #e3e3e3;
    border-radius: 0px;
    font-size: 10px;
    color: #868585;
}
.copyBtn .copyTxt:hover {
    background: #333 !important;
}
.copyBtn span {
    position: absolute;
    top: 50px;
    right: 50px;
}
.alrtBx .alert-info {
    background: #e3e3e3 !important;
    color: #333;
    font-weight: 500;
    font-size: 15px;
}
.alert-dismissible {
    font-weight: 500;
    font-size: 16px;
    top: 10px;
}
.alert.alert-danger{
    width: 100% !important;
    float: left !important;
}
.btn-smb {
    font-size: 13px !important;
    font-weight: 500;
    font-family: 'Barlow';
    padding: 6px 10px !important;
    text-transform: capitalize !important;
    background: #1760ee !important;
}
.inUsr:hover {
    background: #333 !important;
}
.adUsr:hover {
    background: #333 !important;
}
.adUsr {
    background: #1847a2 !important
}
.btnBlk{
    background: #333 !important;
}
.themeBox p {
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
    padding-right: 10px;
    color: #333;
    font-family: 'Barlow';
}
.infoAlrt {
    width: 100%;
    float: left;
    background: #f7f7f7;
    padding: 20px;
    border-radius: 5px;
}
.infoAlrt h4 {
    margin: 0px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
.infoAlrt p {
    font-size: 15px;
    margin-bottom: 3px;
}
.infoAlrt p span {
    font-weight: 500;
    color: #0660f0;
}
.inptFom1 input {
    border: 1px solid #E3E3E3 !important;
    font-size: 14px !important;
    font-family: 'Barlow';
    height: 40px;
}
.inptFom1 .react-select .react-select__control {
    display: inline-flex;
}
.inptFom1 .css-b8ldur-Input {
    margin: 0px;
    padding: 0px;
}

.inptFom1 .react-select__input input {
    width: 100% !important;
    margin: 0px !important;
    border: none !important;
}
.inptFom1 .react-select__value-container {
    padding: 0px 10px 0px 10px;
}
.inptFom1 .react-select .react-select__control .react-select__indicators{
    padding-top: 7px;
}
.dflLft {
    width: 100%;
    float: left;
    text-align: left;
}
.btnBlue {
    background: #1760ee !important;
    font-family: 'Barlow', sans-serif;
    text-transform: capitalize !important;
    font-size: 14px !important;
}
.dflLft .btnBlue:hover, .dflLft .btnBlue:active, .dflLft .btnBlue:focus{
    box-shadow: 0px 5px 12px 0px #1760ee4f !important;
    background: #1760ee !important;
}
.themCard .form-check-sign::before {
    opacity: 0 !important;
}
.themCard .form-check-sign::after {
    opacity: 0 !important;
}

.themCard.activatedTheme{
    background: #0a72fc;
    cursor: pointer;
    box-shadow: 0px 1px 8px #77777770;
}
.themCard.activatedTheme label {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    transition: 0.5s ease;
}
.themCard.activatedTheme label p{
    color: #fff;
    font-weight: 500;
}
.themCard.activatedTheme .acThm{
    display: block
}
.themCard.active {
    background: #0a72fc;
    cursor: pointer;
    box-shadow: 0px 1px 8px #77777770;
}
.themCard.active label {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    transition: 0.5s ease;
}
.themCard.active label p{
    color: #fff;
    font-weight: 500;
}
.themCard:hover{
    background: #0a72fc;
    cursor: pointer !important;
    box-shadow: 0px 1px 8px #77777770;
}
.themCard:hover label {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    transition: 0.5s ease;
}
.themCard:hover label p{
    color: #fff;
    font-weight: 500;
}
.acThm {
    position: absolute;
    right: 20px;
    background: #fff;
    border-radius: 7px;
    color: #0a72fc;
    font-weight: 400;
    font-size: 12px;
    padding: 3px 9px;
    line-height: 17px;
    letter-spacing: 1px;
    display: none;
}
.themCard.active .acThm{
    display: block
}
.dp-box #createProject {
    background: transparent;
    border: none;
    padding: 0px;
}
.react-select__multi-value {
    position: relative;
    top: 4px;
}
.react-select__multi-value__label {
    color: #848484 !important;
    text-transform: lowercase;
}
.react-select .react-select__control {
    min-height: 40px !important;
}
.emailIcon {
        padding: 12px 6px 10px 5px !important;
}