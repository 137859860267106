.userSelect {
    width : 30%
}


.apptesterCard {
    z-index: -1 !important;
}

.closeBtn {
    background-color: #ffffff !important;
    border: 1px solid grey;
    color: rgb(3, 3, 3) !important;
    height: 30px !important;
    width: 30px !important;
    padding: 5px 2px 20px !important;
    text-align: center ! important;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 50% !important;
}
.closeBtn:hover {
    background-color: #ffffff !important;
    color: black !important;
    text-decoration: none !important;
}

.inviteBtnU {
    background-color: rgb(252, 248, 248) !important;
    color:black !important;
    padding: 5px 10px !important;
    text-align: center !important;
    /* text-decoration: none !important; */
    display: inline-block !important;
    font-size: 13px !important;
    border: 0.5px solid black!important;
}

.inviteBtnU:hover {
    background-color:black !important;
    color: white !important;
    padding: 5px 10px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 13px !important;
    border: 0.5px solid white!important;
}

.emailDropdown {
    width: 60%;
    margin-left: 20px;
}
