.ctvHeading {
    text-align: right;
    width: 100%;
    font-size: 15px !important;
    float: left;
    height: 75px;
    margin: 0px;
    color: #494949 !important;
    background: #f5f5f5;
    padding: 25px;
    margin-bottom: 20px;
}

.ctvInfo .row {
    display: inline;
}

.ctvInfo .col-md-7 {
    color: black !important;
    font-weight: 410
}

.ctvCatHeader .row {
    padding: 10px;
}

.ctvCategories {
    font-size: 20px !important;
    color: black !important;
}

.btnBlue {
    margin: 0 !important
}

.ctvLibaryHead .active {
    border-bottom: 2px solid blue;
}

.ctvLibaryHead .underLine {
    border-bottom: 1px solid black;
}

.ctvLibaryHead label {
    font-weight: bold;
    font-size: medium !important;
    color: black !important;
}

.contentHead {
    float: right !important;
    display: flex !important;
}

input.search {
    border-radius: 0px;
    border: 1px solid;
}

.libTblElms {
    width: 100%;
}

.libTblElms .img {
    width: 100%;
    height: auto;

}

.contntLibBtn {
    display: flex !important;
    float: right !important;
}

.contntLibBtn button {
    background: none;
    color: black;
}

.catTitle.row{
    width: 25vh;
}
.catTitleCols{
    display: flex;
    justify-content: space-evenly;
}

.pagBox {
    width: 100%;
    float: left;
}
.pagNo {
    font-weight: 500;
    width: 40%;
    float: left;
    font-weight: 500;
    font-family: 'Barlow', sans-serif;
}
.pagDgt {
    float: left;
    width: 60%;
    text-align: right;
}
.pagDgt .pagination {
    float: right;
}
.pagDgt .pagination li.active a {
    background: #1760ee !important;
}

.pagination{
    margin-top: 0px !important;
}

.ctvnavbar{
    width: 100%;
    float: left;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.ctvnavbar .nav-item a{
    color: white !important;
    background-color: #156EFD !important;
    padding: 20px !important;
    font-size: medium !important;
}

.ctvnavbar .nav-item .active{
    background-color: #073aa1 !important;
}