

.anchorLinkCss a{
    padding-left: none !important;
    color: #ad4547 !important ;
    text-decoration: none;
    background: none !important;
    padding-right: 5px !important;
    padding: 0px 0px  !important;
    margin-left: 2px !important;
    font-size: 15px !important;
    font-family: 'Barlow';
    border-radius: 5px;
    font-weight: 500;
}
.anchorLinkCss {
    color : black !important;
    text-decoration: none;
    font-size: 15px !important;
    font-family: 'Barlow' !important;
    border-radius: 5px;
    font-weight: 400;
}

.flashAlert {
    background: #fbd4d8 !important;
}
