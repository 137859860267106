.modal1 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}
.modal-content1 {
    background: #2b2b2b;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
}

h6 {
    text-align: center;
    margin-bottom: 20px;
}

video {
    display: block;
    margin: 0 auto;
    border-radius: 8px;
    max-width: 100%;
}

.track {
    background: #ddd;
    height: 6px;
    margin: 10px 0;
    position: relative;
    border-radius: 3px;
}

.thumb {
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #999;
    outline: none;
    top: -5px; /* Adjust this value to center the thumb vertically */
}

.slider-labels {
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    padding: 0 5px;
}

.timer-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
}

.timers {
    gap: 30px;
    display: flex;
}

.timers span {
    padding-top: 20px;
}
.duration-display-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: #ffffff;
    font-family: "Arial", sans-serif;
    padding: 4px 10px;
    border-radius: 15px;
    font-size: 14px;

    margin: 10px;
}

.duration-label {
    font-weight: bold;
    margin-right: 5px;
}

.duration-time {
    font-weight: normal;
    min-width: 70px;
    text-align: center;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
    background-color: #2b2b2b; /* Match this to the modal's background */
    padding: 10px; /* Adjust as needed */
}

.modal-actions button {
    border: none;
    padding: 2px 20px;
    margin-right: 10px; /* Space between buttons */
    border-radius: 4px; /* Rounded corners */
    color: white;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
}

.new-clip-actions {
    display: flex;
    align-items: center; /* Vertically center align the elements */
    gap: 10px; /* Space between input and button */
}

.new-clip-actions input {
    flex-grow: 0.1; /* Input takes up the available space */
    padding: 5px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid black; /* A light border for the input */
    border-radius: 4px;
}

.new-clip-actions .save-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.modal-actions button:hover {
    opacity: 0.8;
}

/* Styles for the "Cancel" button */
.modal-actions button:nth-child(1) {
    background-color: #555; /* Dark gray background */
}

/* Styles for the "Save as New Clip" button */
.modal-actions button:nth-child(2) {
    background-color: #777; /* Medium gray background */
}

/* Styles for the "Trim" button */
.modal-actions button:nth-child(3) {
    margin-bottom: 10px;
    background-color: #d9534f; /* Red background */
}

.timer-input-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.timer-symbol-container {
    display: flex;
    align-items: center;
}

.timer-input {
    width: 50px; /* Or adjust to fit the design */
    background: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    text-align: center;
    margin-right: 5px;
}

.timer-symbol-container:not(:last-of-type) .timer-input {
    margin-right: 10px;
}

.timer-symbol-container hr,
.timer-symbol-container min,
.timer-symbol-container sec,
.timer-symbol-container ms {
    margin: 0 5px;
}

.thumb {
    width: 20px; /* Width of the thumb */
    height: 20px; /* Height of the thumb */
    border-radius: 10px; /* Make it round */
    background-color: #fff; /* Background color of the thumb */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Position relative for the absolute positioning of the label */
}

.thumb-value {
    position: absolute; /* Position the label absolutely in relation to the thumb */
    bottom: 100%; /* Place it above the thumb */
    background-color: #000; /* Background color of the label */
    color: #fff; /* Text color */
    padding: 2px 5px; /* Padding inside the label */
    border-radius: 4px; /* Rounded corners */
    margin-bottom: 8px; /* Distance from the thumb */
    font-size: 10px; /* Smaller font size */
    white-space: nowrap; /* Prevent it from wrapping */
}

.current-time-indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px; /* or the width you want for the indicator */
    background-color: red; /* choose an appropriate color */
    transform: translateX(-50%); /* center it on the calculated position */
}

.video-container {
    position: relative;

    width: 640px;
    height: 360px;
}

.video-player {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This will cover the entire area of the container, adjust as necessary */
}

.custom-play-button {
    background-color: #000;
    border-radius: 50%;
    bottom: 20%;
    left: 3%;
    border: none;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    position: relative; /* Position relative for absolute positioning of the icon */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Shadow for depth */
}

.play-icon {
    width: 0;
    height: 0;
    border-top: 12px solid transparent; /* Create triangle */
    border-bottom: 12px solid transparent; /* Create triangle */
    border-left: 20px solid #fff; /* Color of triangle */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.8); /* Center the icon and scale down a bit */
}

.pause-icon {
    width: 16px; /* Width of the pause icon */
    height: 16px; /* Height of the pause icon */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff; /* Color of pause icon */
}
