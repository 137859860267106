



/* .stry-background {
    width: 331px; height: 470px; background: white;margin: 30px;position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
} */

/* input::placeholder, textarea::placeholder {
    color: #000000bf;
} */

/* textarea.storyPage::placeholder { 
    color: #ebebebbf;
} */
/* @keyframes slideUp {
    0% {
      bottom:-100%;
    }
    100% {
      bottom:0;
    }
} */
/* .storyHeader textarea::placeholder { 
    color: #000000bf;
} */

.no_image_available {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background:#bdbdbd78;
    color:#807f7f;
    flex-direction: column;
    text-align: center;
}
.story-card {
    height:78%;
    cursor:pointer;
    width: 100%;
}
.dropdown-toggle-btn {
    color: black !important;
    background: white !important;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    font-size: 17px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}


.webstory-title {
    margin:0;
    font-size:1.2em;
    height: 1.2em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.wbstry-title-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 10px 10px;

}

/*  All Story list CSS */
.grid-view-container {
    cursor: pointer;
    aspect-ratio: 9/16;
    border-radius: 2px !important;
}
.grid-view-content-container {
    position: absolute;
    display: flex;  
    flex-direction: column;
    cursor: pointer;
    z-index: 99;
}
.grid-view-container .ql-editor p {
    font-size: 9px;
}
.grid-view-container .ql-editor h1 {
    font-size: 0.7em;
}
.story-list-card {
    /* padding: 10px; */
    border-radius: 5px;
    background: #ebebeba6;
    position: relative;
}
.story-list-card:hover {
    transform: scale(1.03);
    box-shadow: 0 10px 20px rgb(0 0 0 / 12%), 0 4px 8px rgb(0 0 0 / 6%);
    z-index: 33;
}
.loading-overlay{
    height: 100%;
    width: 100%;
    background: rgba(102, 97, 91, 0.54);
    position: absolute;
    z-index: 60000;
    display: flex;
    justify-content: center;
    padding-top: 14%;
}
.story-tabs {
    width: 33.33%;
    padding: 15px;
    cursor: pointer;
    transition:  all .4s linear;
}
.story-active-tab {
    background: blue;
    color: white;
    font-weight: bold;
}
.story-pages-container {
    top: 4px;
    right: 12px;
    width: 18px;
    height: 32px;
    background: white;
    border-radius: 4px;
    position: absolute;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
}
.story-pages-container::after {
    width: 3px;
    content: "";
    height: 28px;
    background-color: white;
    position: absolute;
    border-radius: 0 4px 4px 0;
    right: -6px;
    top: 0;
    bottom: 0;
    margin: auto;
}
.story-pages-container::before {
    width: 3px;
    content: "";
    height: 28px;
    background-color: white;
    position: absolute;
    border-radius: 4px 0 0 4px ;
    left: -6px;
    top: 0;
    bottom: 0;
    margin: auto;
}
.time-bar {
    background: black;
    color: white;
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    padding: 10px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}
.story-list-card:hover .time-bar {
    visibility: visible;
    opacity: 1;
}
.social_icons {
    display: flex;
    justify-content: space-evenly;
}
.social_icons #shareButton {
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    background-size: cover;
}
/*  Story Template list CSS */
.story-page-container {
    cursor: pointer;
    overflow: hidden;
    aspect-ratio: 9/16;
}
.story-page-container .ql-editor p {
    font-size: 0.6em;
    color:white;

}
.story-page-container .ql-editor p {
    font-size: 1em;
    color:white;
}
.story-content-container {
    position: absolute;
    display: flex;  
    flex-direction: column;
    cursor: pointer;
    z-index: 99;
}
.template-card:hover {
    transform: scale(1.1);
    box-shadow: 0 10px 20px rgb(0 0 0 / 12%), 0 4px 8px rgb(0 0 0 / 6%);
    z-index: 33;
}
.template_loader {
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 100;
    background: white;
    animation: skeleton1 1s linear infinite;
}

@keyframes skeleton1 {
    0% {
        width: 0%;
        opacity: 0;
    }
  
    20% {
        width: 20%;
        opacity: 0.3;
    }
  
    50% {
        width: 50%;
        opacity: 0.5;
    }
  
    100% {
        width: 100%;
        opacity: 0.8;
    }
  }
  
/*  Storyboard Editor CSS */


.ql-editor {
    overflow: hidden; 
    word-break: break-word;
    padding: 0px 0px 0px 1%;
}
.ql-tooltip {
    z-index: 2;
}
.spinner-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 222;
    background: #00000059;
    left: 0;
}
.center-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    color:white
}
.preview-card-container {
    width: 70%;
}
.preview-cover{
    border-radius:2px;
}
.preview-card {
    border: 2px solid #c9c9c9;
    height: 100%;
    padding:0;
    position: relative;
    aspect-ratio:9/16;
    border-radius:4px;
    width: fit-content;
}
.arrow-container{
    cursor: pointer;
    background-color: #ffffff;
    height: 27%;
    margin-bottom: 0.7vw;
    width: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 0.4vw;
    cursor: pointer;
    position: relative;
    box-shadow: 3px 3px 5px 0px #8080805e;
}
.arrow-container-disabled{
    background-color: #ffffff;
    height: 27%;
    margin-bottom: 0.7vw;
    width: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 0.4vw;
    cursor: pointer;
    position: relative;
    opacity: 0.2;
    box-shadow: none;

}
.arrow-container:hover {
    background:rgb(215 206 206);
    transition: 0.5s;
}
.arrow-container img, .arrow-container-disabled img  {
    width: 50%;
}
.bottom-main-bar>div {
    height:100%;
    display:flex; 
    align-items: center;
    justify-content: center;
}
.small-preview-card-container {
   /* width: calc(450px*(9/16)); */
   cursor: pointer;
   /* overflow-x: scroll !important; */
   padding: 9px 0   ;
}
.small-preview-card {
    border: 0.2vw solid #c9c9c9;
    height: 14vh;
    padding:0;
    position: relative;
    aspect-ratio:9/16;
    border-radius:0.4vw;
    width: fit-content;
    opacity: 0.5;
    overflow: hidden;
    font-size: 1vw;
}
.small-preview-card h1{
    font-size: 2.7px !important;
    margin:0;
}
.small-preview-card h2, .small-preview-card h3, .small-preview-card h4,.small-preview-card h5,.small-preview-card h6{
    font-size: 2.5px !important;
    margin:0;
}
.small-preview-card p{
    font-size: 2px !important;
    margin:0;
}
.small-preview-card .embed-container div{
    font-size: 0.3vw !important;
    border: 0.1vw solid #ebebeb;
}
.small-preview-card .webstory-author-name {
    font-size: 0.2vw;
    margin: 0 0.1vw 0vw 0;
}
.twitter-picker  {
    box-shadow: none !important;
    width: 100%!important;
}
.twitter-picker div {
    padding: 0 !important;    
}
.webstory-edit-options {
    background: white;
    /* padding: 1.3vw ; */
}
.webstory-edit-options .col-sm-12>div {
  padding: 0.3vw 1.3vw 0.65vw;
  border-top: 0.2vw inset #85858575!important;
} 
.webstory-edit-options .col-sm-12>h6{
  font-size: 1.2vw;
  text-align: center;
  background: rgb(157, 157, 157);
  margin: 0px;
  color: white;
}
.action-box {
    background-color: #ffffff;
    height: 3vw !important;
    width: 3vw !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 0.4vw;
    cursor: pointer;
    font-size: 1.5vw;
    position: relative;
    box-shadow: 3px 3px 5px 0px #8080805e;
}
.action-box:active {
    box-shadow: inset 3px 3px 5px 0px #0000005e;
    background: #787373;
}
.action-box.open {
    box-shadow: inset 3px 3px 5px 0px #0000005e;
    background: #787373;
}

/* .sliding-box {
  background-color: #ffffff;
  height: 3vw !important;
  width: 3vw !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border-radius: 0.4vw;
  cursor: pointer;
  font-size: 1.5vw;
  position: relative;
  box-shadow: 3px 3px 5px 0px #8080805e;
}

.sliding-box .tooltiptext{
  visibility: hidden;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-align: center;
  transition: transform 1s;

}
.sliding-box .tooltiptext.right {
  right: 0;
  left: auto;
}
.sliding-box .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 31%;
  left: -3%;
  margin-left: -7px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent rgb(255, 255, 255) transparent transparent;
}
.sliding-box .tooltiptext.right::after {
  border-color: transparent;
  right: -1vw;
  left: 0vw;
  top: 0;
  border-width: 1vw;
  box-sizing: border-box;
}


.sliding-box:hover .tooltiptext, .sliding-box.tooltiptext:hover {
  visibility: visible;
  transform: translateX(-4vw);

}
.sliding-box .tooltiptext * {
  z-index: 99;
} */
.tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 3px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 50px;
    font-size: 14px;
  }
  .tooltiptext.right {
    right: 50px;
    left:auto;
  }
  .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 31%;
    left: -3%;
    margin-left: -7px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
  .tooltiptext.right::after {
    border-color: transparent transparent transparent black;
    right: -9%;
    left:auto;
  }
  .action-box:hover .tooltiptext {
    visibility: visible;
  }
  .auto_advance {
    cursor: help;
    font-size: 12px;
    margin-right: 5px;
    border: 2px solid grey;
    border-radius: 50%;
    padding: 5px;
  }
  .auto_advance .tooltiptext.right {
    color: black;
    width: 20%;
    top: -9.2vw;
    background-color: #ffcc00;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.2vw;
    font-family: sans-serif;
    font-size: 1vw;
    text-align: justify;
    padding: 1vw;
    line-height: 1.4em;
    left: 0;
    right: auto;
    z-index: 99999;
    text-transform: capitalize;
  }
  .auto_advance .tooltiptext.right::after {
    right: -12px;
    left: auto;
    bottom: auto;
    top: 7%;
    content: none;
  }
  .auto_advance:hover .tooltiptext {
    visibility: visible;
  }
  .final-actions {
      width: 45%;
      margin: 0 !important;
      border-radius: 20px !important;
      text-transform: none !important;
  }
  .final-actions.save {
    background-color: #2F80ED ;
  }
  .final-actions.publish {
    background-color: #12B76A ;
  }
  #absolute-top-alert {
    position: absolute !important;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    width: -moz-fit-content !important;
    width: fit-content !important;
    box-shadow: 2px 2px 8px rgb(0 0 0 / 50%);
    z-index: 22222;
    /* background: white; */
    font-family: monospace;
    font-weight: bold;
    /* animation-name: slideDown;
    animation-duration: 2s;
    animation-timing-function:ease; */
  }
  /* #absolute-top-alert.alert.alert-success {
    color: #479d43;
    color: #070800;

    border: 0.3vw solid #479d43;
  }
  #absolute-top-alert.alert.alert-danger {
    color: #c45142;
    border: 0.3vw solid #c45142;
  }
  */
  #absolute-top-alert.alert.alert-warning {
    color: #070800;
    /* border: 0.3vw solid #fbc658; */
    /* box-shadow: 0.2vw 0.2vw 1vw 0.2vw; */
  } 
/* @keyframes slideDown {
    0% {
      top:0;
    }
    60% {
        top:50%;
    }
    100% {
      top:50%;
    }
} */
  /* // storypage list  */
  .published-strip {
    position: absolute;
	  font-weight: 700;
	  padding: 0.25rem 1rem;
    font-family: serif;
	  text-transform: uppercase;
    color: #0A9928;
    border: 5px ridge #0A9928;
    transform: rotate(-14deg);
    background-color: white;
    z-index: 2;
    margin: auto;
    left: 0;
    right: 0;
    width: fit-content;
    top: 0;
    bottom: 0;
    height: fit-content;
    box-shadow: -8px 6px 13px 1px #fff7f780;
  }
  .story-list-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  .publish-close {
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    height: 32px;
    opacity: 1;
  }
  .publish-close:hover {
    opacity: 1;
  }
  .btn[disabled] {
    background-color: black !important;
  }
  .publish-close:before, .publish-close:after {
    position: absolute;
    left: 48%;
    content: ' ';
    height: 16px;
    width: 2px;
    top: 25%;
    background-color: #c7c7c7;
  }
  .publish-close:before {
    transform: rotate(45deg);
  }
  .publish-close:after {
    transform: rotate(-45deg);
  }
  
@media only screen and (max-width: 768px) {
    .story-list-container {
        display: flex;
        flex-flow: column;
        align-items: center;
      }
}
.shake {
    animation: shake 0.5s;
}
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}
.embed-container {
    position: absolute;
    width: 100%;
    margin: auto;
    height: 60%;
    display: flex;
    top: 8%;
    font-size: 1.2vw;
    justify-content: center;
    align-items: center;
    text-align: left;
    z-index: 4;
}
.embed-container div {
    width: 80%;
    height: 100%;
    background: #1313135e;
    border-radius: 8%;
    padding: 10%;
    border: 0.2vw solid #ebebeb;
    color: white;
    font-weight: bolder;
    font-family: sans-serif;
}
.embed-container iframe  {
    width: 91% !important;
    height: 100% !important;
    border: 1px solid grey !important;
    border-radius: 8px !important;
    min-width: auto !important;
    margin: 0 !important;
    padding: 0 !important;
}
.media-icon {
    position: absolute;
    z-index: 88;
    width: 2em;
    height: 2em;
    line-height: 1.3em;
    text-align: center;
    right: 2px;
    background: #ffffffb3;
    padding: 4px;
    border-radius: 10%;
    top: 4px;
}
/* reorder css */

.reorder-card {
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
}
.reorder-icon {
    position: absolute;
    z-index: 199;
    color: white;
    left: 0;
    margin: auto;
    right: 0;
    font-size: 2em;
    top:30%;
}
.page-link {
    z-index: 1 !important;
}


.separator {
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
  }
  
  .separator:not(:empty)::before {
    margin-right: .25em;
  }
  
  .separator:not(:empty)::after {
    margin-left: .25em;
  }

  .small-preview-card-container::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	/* border-radius: 10px; */
	background-color: #F5F5F5;
}

.small-preview-card-container::-webkit-scrollbar
{
	height: 5px;
	background-color: #F5F5F5;
}

.small-preview-card-container::-webkit-scrollbar-thumb
{
	/* border-radius: 10px; */
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
.small-template-card .headline p{
    font-size: 7px;
}
.select-template button {
    margin: 0;
    padding: 9px;
    margin-bottom: 6px;
}
.small-template-card-container::-webkit-scrollbar-thumb
{
	/* border-radius: 10px; */
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
.small-template-card-container::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	/* border-radius: 10px; */
	background-color: #F5F5F5;
}

.small-template-card-container::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}
.modal-dialog {
    padding-top: 0 !important;
}
.story-fixed-bottom-bar{
    position:absolute;
    bottom:0;
    width:100%;
    text-align:center;
    color:white;
    background:#313131e8;
    z-index: 101;
}
.bottom-action-buttons button{
    padding: 1vw;
    font-size: 1vw;
    border-radius: 2vw !important;
}
.fs-1vw {
    font-size: 1vw !important;
}


.show-author-webstory .switch {
    position: relative;
    display: inline-block;
    width: 4vw;
    height: 2vw;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .show-author-webstory .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: transform .4s;
  }
  
  .show-author-webstory .slider:before {
    position: absolute;
    content: "";
    height: 1vw;
    width: 1vw;
    left: 0.4vw;
    bottom: 0.5vw;
    background-color: white;
    -webkit-transition: .4s;
    transition: transform .4s;
  }
  
  .show-author-webstory input:checked + .slider {
    background-color: #2196F3;
  }
  
  .show-author-webstory input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  .show-author-webstory input:checked + .slider:before {
    -webkit-transform: translateX(2vw);
    -ms-transform: translateX(2vw);
    transform: translateX(2vw);
  }
  .webstory-author-name {
    z-index: 100;
    position:absolute;
    bottom:0; 
    right:0; 
    text-align: right;
    font-size: 1vw;
    font-family: 'Monospace';
    margin: 0 0.3vw 0.5vw 0;
    background-color: rgba(255, 255, 255, 0.294) !important;
    color: black !important;
  }
  .set-background .action-box{
    width: 50% !important;
  }
  .set-background .action-box.open{
    background: #78737340;
  }
  .media-library-img-container{
    width: 7vw;
    margin: 0 0 0.5vw;
    border: 1px solid grey;
    cursor: pointer;
    position: relative;
    border-radius: 0.3vw;
  }
  .media-library-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    overflow-y: scroll;
    height: 50vh;
    padding-top: 1vw;
    background: #f5eeee;
    border-radius: 1vw;
  }
  .media-library-img-container div {
    aspect-ratio: 9/16;
}
.background-options {
  width: 100%;
}
  input[type=range] {
    -webkit-appearance: none;
    margin:0;
    width: 100%;
    border:none;
    background: #3071a9;
    padding:0
  }
  input[type=range]:focus {
    outline: none;
    border:none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5vw;
    cursor: pointer;
    box-shadow: 0.1vw 0.1vw 0.1vw #000000, 0px 0px 0.1vw #0d0d0d;
    background: #3071a9;
    border-radius: 0.1vw;
    border: 0.1vw solid #010101;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0.1vw 0.1vw 0.1vw #000000, 0px 0px 0.1vw #0d0d0d;
    border: 0.1vw solid #000000;
    height: 1vw;
    width: 0.5vw;
    border-radius: 0.3vw;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -0.4vw;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #367ebd;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 0.1vw 0.1vw 0.1vw #000000, 0px 0px 0.1vw #0d0d0d;
    border: 0.1vw solid #000000;
    height: 2vw;
    width: 1vw;
    border-radius: 0.3vw;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -0.5vw;
  }
  .background-options input[type=range]{
    margin: .5vw 0;
  }

.background-options div{
  margin-bottom: .3vw;
}
.bottom-reorder-listing {
  transition: all .5s ease-in-out;
  height: 0;
}

.bottom-reorder-listing.open {
  height : 18vh;
 }

 .storyboard-container ::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
   background-color: #F5F5F5;
 }
 
 .storyboard-container ::-webkit-scrollbar {
   height: 0.4vw;
   width: 0.4vw;
   background-color: #F5F5F5;
 }
 
 .storyboard-container ::-webkit-scrollbar-thumb {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
   background-color: #555;
 }
 #bg_animations, #text_animations {
  text-transform: capitalize;
 } 