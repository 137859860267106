body{
    font-family: 'Barlow', sans-serif;
}
.dp-box{
    padding: 0px 10px 10px 10px;
}
.dp-box table th {
    text-align: left;
    font-weight: 500;
    color: #444;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 15px 7px;
    font-size: 15px;
    font-family: 'Barlow', sans-serif;
}
.dp-box .table .text-right {
    text-align: right !important;
}
.pb-20 .card-body {
    padding-bottom: 20px !important;
}
#navbarDropdownMenuLink:after {
    display: none;
}
.user {
    background: #f1f1f1;
    margin-top: 0px !important;
    padding-top: 20px;
}
.logo:after {
    display: none;
}
.user:after {
    display: none;
}

.sidebar-wrapper .nav li.active a {
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li.active a i {
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li a i{
    font-size: 20px !important;
}
.sidebar-wrapper .nav li:hover a{
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li:hover a i{
    color: #0660f0 !important;
}
.info a span {
    text-transform: uppercase;
    font-family: 'Barlow';
    font-weight: 500;
}
.minBtn button{
    background: #d4d3d3;
}
.minBtn button i {
    color: #7c7e82;
}
.minBtn button:hover, .minBtn button:focus{
    background: #0d61f0 !important;
}
.minBtn button:hover i, .minBtn button:focus i {
    color: #fff !important;
}
.logo-mini{
    display: none !important;
}
.sidebar-mini .logo-mini{
    display: block !important;
}
.logo-normal img {
    width: 120px;
    padding-left: 10px;
}
