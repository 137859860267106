.button {
    background-color: #473FF2 !important;
    transition: all 0.3s ease;
    border-radius: 0.65rem !important;
    transform: scale(1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .button:hover {
    transform: scale(1.05);
    background-color: #10caeb;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  