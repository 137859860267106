    .greeniconcolor {color:green;}
    .rediconcolor {color:red}

    .lblNme-1 {
    font-size: 14px !important;
    color: #252422 !important;
}
.btnGry {
    background: #66615B;
    font-size: 14px;
    text-transform: unset;
    font-weight: 500;
    font-family: 'Barlow';
}