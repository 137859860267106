@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600&display=swap');

body{
    font-family: 'Barlow', sans-serif;
}

.dp-box{
    padding: 0px 10px 10px 10px;
}
.mt-30{
    margin-top: 30px !important;
}
#navbarDropdownMenuLink:after {
    display: none;
}
.user {
    background: #f1f1f1;
    margin-top: 0px !important;
    padding-top: 20px;
}
.logo:after {
    display: none;
}
.user:after {
    display: none;
}

.sidebar-wrapper .nav li.active a {
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li.active a i {
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li a i{
    font-size: 20px !important;
}
.sidebar-wrapper .nav li:hover a{
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li:hover a i{
    color: #0660f0 !important;
}
.info a span {
    text-transform: uppercase;
    font-family: 'Barlow';
    font-weight: 500;
}
.minBtn button{
    background: #d4d3d3;
}
.minBtn button i {
    color: #7c7e82;
}
.minBtn button:hover, .minBtn button:focus{
    background: #0d61f0 !important;
}
.minBtn button:hover i, .minBtn button:focus i {
    color: #fff !important;
}
.logo-mini{
    display: none !important;
}
.sidebar-mini .logo-mini{
    display: block !important;
}
.logo-normal img {
    width: 120px;
    padding-left: 10px;
}



.card-stats {
    background-image: url(https://mcmscache.epapr.in/post_images/website_132/new_post_images/618b65adac2b2_circles.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #0c61f0 !important;
    background-position: right;
}
.zooM:hover {
    transform: scale(1.1);
}
.card-stats .numbers h2 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
    font-family: 'Barlow', sans-serif;
}
/* .card-stats {
    background-image: url(https://mcmscache.epapr.in/post_images/website_132/new_post_images/618b6a5c80810_dots.png);
} */
.card-stats {
    padding: 20px;
    display: flow-root !important;
}
.icnBx {
    background: #fff3;
    padding: 9px;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    float:left;
    text-align: center;
}
.icnBx i {
    font-size: 29px;
    color: #f4f3ef;
    padding-top: 7px;
}
.card-stats .numbers {
    float: left;
    width: calc(100% - 70px);
    text-align: right;
}
.card-stats .numbers p {
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Barlow', sans-serif;
}
.statsNme {
    width: 76%;
    float: left;
    text-align: right;
}
.statsNme h3 {
    font-size: 20px;
    font-family: 'Barlow';
    color: #fff;
    font-weight: 500;
    margin-bottom: 0px;
}
.statsEdt {
    float: left;
    text-align: center;
    width: 30px;
}
.statsEdt {
    float: left;
    text-align: right;
    width: 40px;
}
.statsEdt a i {
    color: #fff;
    padding-top: 9px;
}
.mngInf {
    width: 100%;
    float: left;
    border-top: 2px solid #de5b5b;
    padding-top: 10px;
    margin-top: 15px;
}
.dtlUsr {
    width: 50%;
    float: left;
    color: #eaeaea;
    font-family: 'Barlow';
    font-weight: 500;
}
.dtlUsr i {
    color:#f1b4b4;
    font-size: 17px;
    padding-right: 6px;
}
.dtlUsr a {
    text-decoration: none;
    color: #eaeaea;
}
.difsts {
    background-image: url(https://mcmscache.epapr.in/post_images/website_132/new_post_images/618b65adac2b2_circles.png);
    background-size: cover;
    background-color: #ec6262 !important;
}

.noRslt {
    font-size: 14px !important;
    font-weight: 400;
    color: #969696;
    text-align: center;
}
.mb3 {
    margin-bottom: 30px !important;
}

.alert-dismissible{
    font-weight: 500;
    font-size: 16px;
}
.alert-dismissible a {
    padding-left: 10px;
    color: #ffffff;
    text-decoration: none;
    background: #39a205;
    padding-right: 10px;
    padding: 6px 11px;
    border-radius: 5px;
    margin-left: 10px;
    font-size: 13px;
    font-family: 'Barlow';
    
}
.alert-dismissible a:hover{
    background-color: #333;
    color: #fff !important;
}
.alert-dismissible button.close {
    background: #0000001f !important;
    border: none !important;
    color: #fff;
    font-weight: 400;
}
.heding_dsh_h4{
    font-size: 18px !important;
    text-align: left;
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    color: #000;
    width: 100%;
    float: left;
}
.dlIcn i {
    font-size: 17px;
    color: #777;
}
.dlIcn svg {
    font-size: 17px;
    color: #777 !important;
}
.dlIcn:hover i{
color: #1760ee;
}
/* .table tr:hover {
    background: #f7f7f7;
} */
.statsNme p{
 margin-bottom: 0px;
}
.statsNme p a {
    color: #460808;
    text-decoration: none;
    font-size: 16px;
}
.dtlUsr a:hover {
    color: #fff;
}
.dtlUsr a:hover i  {
    color: #fff !important;
}
.dtlUsr:hover i{
    color:#fff;
}
.dtlUsr:hover{
    color:#fff;
}
.fs label {
    font-size: 15px !important;
    font-family: 'Barlow';
    margin-bottom: 0px;
}
.fs {
    margin-bottom: 0px !important;
}
.mb0 .form-group {
    margin-bottom: 0px !important;
}
.pagDgt-s {
    float: left;
    width: 100%;
    text-align: center;
}
.pagDgt-s .pagination {
    text-align: center;
    width: min-content;
    margin: 10px auto;
}
.pagDgt-s .pagination li.active a {
    background: #1760ee !important;
}
.pagNo-s {
    text-align: center;
    width: 100%;
    float: left;
    font-weight: 500;
    margin-top: 10px;
    font-family: 'Barlow', sans-serif;
}

.recntArtc_dv img {
    vertical-align: text-bottom;
}

.phonenumberpopup .bg {
    background-color:#2F80ED;
    position:absolute;
    left:0;
    right:0;
    top:0;
    height:15%;
    z-index: -25;
}

.phonenumberpopup img{
    height:47%;
    width:35%;
    padding-bottom:10px;
    margin-top: 0px;
}
.phonenumberpopup .cancelBtn{
    position: absolute;
    font-size: 20px;
    font-weight: normal;
    height: 15%;
    top: 0px;
    right: -5px;
    background-color: rgb(47, 128, 237);
    color: black;
    margin-top: 0px; 
}
.phonenumberpopup .title{
    font-size: 20px;
    font-weight: bold;
    color: black;
    margin-top: 10px;
    margin-bottom: 10px;
}
.checkedboxclass{
    color: black;
    text-align:center;
    justify-content:center;
}
.form-check-input[type=checkbox]{
    border-color: black;
    border-width: 1px;
    margin-left: 15px;
    margin-top: 15px;
}
.react-select__value-container {
    width: 75%;
}

.webstoryStrickyBtn {
    position: fixed;
    bottom: 6%;
    right: 7vw;
    background-color: #0c61f0;
    border: none;
    padding: 12px 14px;
    font-size: 19px;
    border-radius: 21px;
    cursor: pointer;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    transition: background 0.3s;
  }
  
  .webstoryLink {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
  }

  .webstoryLink:hover{
    color: #ffffff !important;
  }