@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600&display=swap');

body{
    font-family: 'Barlow', sans-serif;
}
.sidebar-wrapper .nav li.active a {
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li.active a i {
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li a i{
    font-size: 20px !important;
}
.sidebar-wrapper .nav li:hover a{
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li:hover a i{
    color: #0660f0 !important;
}
.info a span {
    text-transform: uppercase;
    font-family: 'Barlow';
    font-weight: 500;
}
.minBtn button{
    background: #d4d3d3;
}
.minBtn button i {
    color: #7c7e82;
}
.minBtn button:hover, .minBtn button:focus{
    background: #0d61f0 !important;
}
.minBtn button:hover i, .minBtn button:focus i {
    color: #fff !important;
}
.logo-mini{
    display: none !important;
}
.sidebar-mini .logo-mini{
    display: block !important;
}
.logo-normal img {
    width: 120px;
    padding-left: 10px;
}
#navbarDropdownMenuLink {
    margin-right: 0px;
}

/* ------------ */
.dp-box{
    padding: 0px 10px 10px 10px;
}

/* .dp-box table {
    margin-bottom: 50px;
} */

.user {
    background: #f1f1f1;
    margin-top: 0px !important;
    padding-top: 20px;
    border-right: 1px solid #ddd;
}

.logo:after {
    display: none;
}
.user:after {
    display: none;
}
#navbarDropdownMenuLink:after {
    display: none;
}
h4.heding_tab_h4 {
    font-size: 18px !important;
    text-align: left;
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    color: #000;
    width: 50%;
    float: left;
}
.dp-box table th {
    text-align: left;
    font-weight: 500;
    color: #444;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 15px 7px;
    font-size: 15px;  
    font-family: 'Barlow', sans-serif;
}
.dp-box table tr td {
    text-align: left !important;
    font-size: 14.2px;
    color: #666;
    font-family: 'Barlow', sans-serif;
}
.pd-b15 {
    padding-bottom: 15px;
}
.lftTblelm {
    position: relative;
    top: 34px;
    font-weight: 500;
    font-family: 'Barlow';
    font-size: 16px;
    text-align: left;
    padding-left: 7px;
    width: 180px;
    z-index: 1;
}
.btnBlue {
    background: #1760ee !important;
    font-family: 'Barlow', sans-serif;
    text-transform: capitalize !important;
    font-size: 14px !important;
}
a.btnBlue:hover, a.btnBlue:active, a.btnBlue:focus{
    box-shadow: 0px 5px 12px 0px #1760ee4f !important;
    background: #1760ee !important;
}
.card-footer {
    text-align: left !important;
}
.dp-box .table {
    margin-bottom: 40px;
}
.mb-25 {
    margin-bottom: 25px !important;
}
.mt-30{
    margin-top: 30px !important;
}

.dfltDiv {
    width: 100%;
    float: left;
    padding-top: 20px;
}
.dfltDiv h4 {
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    font-size: 20px !important;
}

.subsCard table tr {
    border-bottom: 1px solid #fff0;
    padding: 0px !important;
}
.subsCard table tr td {
    padding: 0px !important;
    text-align: left !important;
    font-family: 'Barlow';
    padding-bottom: 7px !important;
}
.subsCard {
    border: 1px solid #f1f1f1;
    padding: 50px 15px 20px;
    width: 110%;
    height: 95%;
    /* height: 480px; */
    border-radius: 15px;
    margin-top: 30px;
    position: relative;
    /* box-shadow: 0px 25px 26px -15px rgb(204 204 204);
    -webkit-box-shadow: 0px 14px 26px -15px rgb(204 204 204);
    -moz-box-shadow: 0px 25px 26px -15px rgba(204,204,204,1); */
    background: #f9f9f9ee;
}
.cutmPlanBtn:hover, .cutmPlanBtn:visited {
    color: #fff !important;
    background: #1558db;
}
.subsCard h6 {
    letter-spacing: 2px;
    font-weight: 500;
    color: #1760ee;
    font-family: 'Barlow';
    font-size: 16px;
    text-align:left;
}
.plnCrd {
    width: 95%;
    margin: 0px auto;
}
.subsCard h3 {
    font-family: 'Barlow';
    font-weight: 600;
    color: #7c7c80;
    font-size: 26px;
    /*margin-bottom: 0px*/
}
.rate{
    width: 110%;
    float: left;
}
.popularPln {
    float: left;
    text-align: left;
    margin-bottom: 4px;
    height: 24px
}
.popularPln b {
    background: rgb(26 177 219);
    padding: 3px 10px 4px;
    line-height: 32px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 6px;
    position: relative;
    top: -6px;
    margin-left: 10px;
    color: #ffffff;
    border: 1px solid #fff;
}
.subsCard a {/*
    position: absolute;*/
    bottom: 20px;
    text-align: center;
    /*left: 50%;
    transform: translate(-50%, -50%);*/
    bottom: 0px;
    width: 100%

}
.subsCard:hover{
    background: #1760ee;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #1760ee, #00B4DB);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #1760ee, #00B4DB); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
cursor: pointer;
box-shadow: 0px 3px 11px #0000005c
}
.subsCard:hover h6 {
    color: #ffffff;
}
.subsCard:hover h3{
    color: #fff;
    /* font-size: 30px; */
}
.subsCard:hover table tr td{
    color:#fff
}
.subsCard:hover .btnBlue{
    background-color: #fff !important;
    color: #1760ee;
}
.subsCard .btnBlue:hover{
    color: #1760ee !important;
}
.subsCard:after {
    content: "";
    width: 30px;
    height: 48px;
    background: #fff;
    display: block;
    position: absolute;
    top: 0px;
    right: 20px;
    border-bottom: 20px solid #08aadd;
    border-left: 15px solid #fff;
    border-right: 15px solid #fff;
    display: none;
}
.subsCard:hover::after{
    display: block;
}
.premiumPlan {

        background: #1760ee;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #1760ee, #00B4DB);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #1760ee, #00B4DB); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
cursor: pointer;

}
.premiumPlan lb {
    position: absolute;
    top: 26px;
    right: -50px;
    width: 200px;
    padding-top: 6px;
    padding-bottom: 4px;
    background-color: #f93755;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.premiumPlan h6 {
    color: #ffffff;
    float: left;
}
.premiumPlan h3{
    color: #fff;
    /* font-size: 30px; */
}
.premiumPlan table tr td{
    color:#fff
}
.premiumPlan .btnBlue{
    background-color: #fff !important;
    color: #1760ee;
}
.premiumPlan .btnBlue:hover{
    color: #1760ee !important;
}
/*.premiumPlan:after {
    content: "POPULAR!";
    width: 50%;
    height: 20px;
    align-items: center;
    position: relative;
    top: -330px;
    right: 22px;
    transform: rotate(-45deg);
    border-bottom: 20px solid #f80303;
    border-left: 14px solid  #ffffff;
    border-right: 15px solid #ffffff;
    display: block;
    z-index: 999;
}*/
/* .premiumPlan::after{
    display: block;
} */
.monthLabel {
    font-family: 'Barlow';
    font-weight: 600;
    color: #0c0c0c;
    font-size: 56px;
}


/* ========== */

.checkOutCard {
    border: 1px solid #f1f1f1;
    padding: 50px 0px 40px;
    width: 100%;
    border-radius: 10px;
    margin-top: 30px;
    position: relative;
    background: #1760ee;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #1760ee, #00B4DB);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #1760ee, #00B4DB); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    cursor: pointer;
    margin-bottom: 30px;
}
.checkOutCard h6 {
    letter-spacing: 2px;
    font-weight: 500;
    color: #fff;
    font-family: 'Barlow';
    font-size: 16px;
}
.plnCrd {
    width: 90%;
    margin: 0px auto;
}
.checkOutCard h3 {
    font-family: 'Barlow';
    font-weight: 600;
    color: #7c7c80;
    font-size: 26px;
}
.checkOutCard a {
    position: absolute;
    bottom: 20px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 0px;
}
.paymnt_img img {
    width: 100%;
}
.paymnt_img {
    width: 100%;
    float: left;
    padding-top: 50px;
}
.pCardTbl tr td {
    color: #fff !important;
    font-family: 'Barlow' !important;
    font-size: 15px !important;
    font-weight: 500;
    opacity: 1 !important;
}
.checkOutCard .pCardTbl tr {
    padding: 0px 20px !important;
    width: 100% !important;
    float: left;
}
.checkOutCard .pCardTbl tr td {
    width: 50%;
    float: left;
}
.checkOutCard .pCardTbl tr td:last-child {
    text-align: right !important;
    font-size: 18px !important;
    font-weight: 700;
}
.checkOutCard .pCardTbl hr {
    margin-left: 5%;
    float: left;
    width: 90%;
    color: #fff;
}
.checkOutCard:after {
    content: "";
    width: 30px;
    height: 50px;
    background: #fff;
    display: block;
    position: absolute;
    top: 0px;
    right: 20px;
    border-bottom: 20px solid #08aadd;
    border-left: 15px solid #fff;
    border-right: 15px solid #fff;
}
.checkOutCard .btnBlue{
    background-color: #fff !important;
    color: #1760ee;
}
.paymnt_img h1 {
    font-size: 30px;
    text-align: left;
    font-weight: 600;
    font-family: 'Barlow';
    margin-bottom: 10px;
}
.paymnt_img h2 {
    text-align: left;
    font-size: 20px;
    color: #1463ed;
    font-weight: 600;
    margin-top: 0px;
    font-family: 'Barlow';
}
.paymnt_img img {
    width: 100%;
    padding-top: 8%;
}
.paymnt_img {
    text-align: left;
    float: left;
}
.paymnt_img button {
    font-family: 'Barlow';
    display: inline-block;
    padding-bottom: 7px;
    text-transform: capitalize;
    font-size: 14px;
}
.paymnt_img button:hover {
    background: #333 !important;
}

.dp-box-plan table th {
    text-align: left;
    font-weight: 500;
    color: #444;
    padding: 15px 7px;
    font-size: 15px;  
    font-family: 'Barlow', sans-serif;
}
.dp-box-plan table tr td {
    text-align: left !important;
    font-size: 14.2px;
    color: #666;
    font-family: 'Barlow', sans-serif;
}
.dp-box-plan .table {
    margin-bottom: 40px;
}
.prTg td {
    color: #a9a9a9 !important;
    font-size: 16px !important;
}
.mt0 {
    margin-top: 0px !important;
}
.dp-box-plan .fa.fa-check {
    color: #20d200;
}
.dp-box-plan .fa-times {
    color: #9a9c9a;
}
.dp-box-plan table i {
    font-size: 18px;
}
.bNm {
    font-size: 19px !important;
    color: #1760ee !important;
    font-family: 'Barlow', sans-serif;
}
.subsCard table tr:hover {
    background: transparent !important;
}
.pLanHead h4 {
    width: 70%;
    text-align: left;
    padding-left: 30px;
    float: left;
    font-family: 'Barlow', sans-serif !important;
}
.card-signup h4.card-title{
     font-family: 'Barlow', sans-serif !important;
}
.cutmPlanBtn {
    background: #1760ee;
    display: inline-block;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding: 9px 31px;
    border-radius: 13px;
    text-decoration: none;
    float: right;
    margin-right: 30px;
    font-family: 'Barlow', sans-serif;
}
.cutmPlanBtn span {
    display: block;
    font-size: 11px;
    font-weight: 500;
    opacity: 0.9;
    font-family: 'Barlow', sans-serif;
}
.rate h3{
    float: left;
    margin-bottom: 10px;
    text-align: left;
}
.rate b {
    float: left;
    position: relative;
    padding-left: 10px;
    top: 8px;
    font-size: 14px;
    font-family: 'Barlow', sans-serif;
}
.subsCard table tr td i {
    padding-right: 8px;
}
.premiumPlan .rate b{
    color: #fff
}
.subsCard:hover .rate b{
    color: #fff
}
.offRate {
    width: 100%;
    float: left;
    text-align: left;
    margin-bottom: 15px;
}

.offRate b {
    display: inline-block;
    background: #938f8f;
    color: #fff;
    padding: 3px 5px;
    /* border-radius: 20px; */
    font-size: 10px;
    font-weight: 500;
}
.premiumPlan .offRate b {
    background: #fff;
    color: #333;
}
.premiumPlan:after {
    content: "";
    width: 30px;
    height: 40px;
    background: #fff;
    display: block;
    position: absolute;
    top: 0px;
    right: 20px;
    border-bottom: 20px solid #08aadd;
    border-left: 15px solid #fff;
    border-right: 15px solid #fff;
   
}
.premiumPlan .benFHed {
    text-align: left;
    width: 100%;
    float: left;
    color: #fff;
    font-size: 17px;
    margin-bottom: 10px;
    font-weight: 600;
}
.benFHed {
    text-align: left;
    width: 100%;
    float: left;
    color: #1760ee;
    font-size: 17px;
    margin-bottom: 10px;
    font-weight: 600;
}
.subsCard:hover .benFHed{
    color: #fff
}
b.yrpMonth {
    position: relative;
    top: -15px;
    left: -7px;
}
.subsCard ul li {
    line-height: initial;
    text-align: left;
    margin-bottom: 4px;
}
.subsCard ul {
    padding-left: 0px;
}
.subsCard img {
    padding-right: 5px;
}
.subsCard ul li b{
    padding-right: 4px;
}
.subsCard:hover ul li{
    color: #fff;
    /* font-size: 30px; */
}
.premiumPlan ul li {
    color: #fff;
    /* font-size: 30px; */
}
.userPurchase_plan{}
.activeCrd {
    text-align: left;
    position: absolute;
    top: 20px;
    left: 15px;
    background: #04b24b;
    color: #fff;
    padding: 1px 7px;
    font-size: 12px;
    display: inline-block;
    border-radius: 3px;
    line-height: 17px;
    font-weight: 500;
}
.subsCard:hover .offRate b{
    color: #333;
    background: #fff;
}


.couponBox .form-container {
    display: flex;
}
.couponBox {
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    display: flex;
    
}
.couponTextWrapper {
    display: flex;
    justify-content: center;
    font-family: "Gilroy-Medium";   
}
.couponText {
    font-family: "Gilroy-Medium";
    font-size: 1.5vw;
    display: block;
    text-align: center !important;
    color: #4b4f60;
}
.couponCodeInputWrapper {
    display: flex;
    flex-direction: row;
    border :0.5px solid ;
    border-radius: 25px;

}
.couponCodeInput {
    border: none;
    padding: 10px;
    border-radius: 25px;
    margin-left: 30px;
    width: 170px;
    font-size: 14px;
}
.couponCodeInput:focus {
    outline: none;
    padding: 10px;
}
.couponCodeSubmitButton {
    /* width: 10vw; */
    /* height: 6vh; */
    padding: 8px;
    width: 100px;
    background-color: #1760ee;
    border-radius: 0 25px 25px 0;
    border: none;
    color: #fff;
}
.couponCodeButtonText {
    font-family: "Gilroy-Bold";
    text-align: center;
    font-size: 1.5vw;
    color: #fff;
}

.couponApplied {
    border : 1px solid black !important;
    border-radius: 0px;
}
.couponApplied h2{
    font-size : 20px;
    font-weight: 600;
    color : green;
    margin-bottom : 15px;
}
.couponApplied h3{
    font-size : 15px;
    font-weight: 600;
    color : green;
    margin-bottom : 0px;
}

.btn-round {
        border-width: 1px;
        border-radius: 30px;
        padding-right: 10px !important;
        padding-left: 10px !important;
}

