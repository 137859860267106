.dp-box .text-center{
    text-align: center !important;
}
.dp-box .table-responsive {
    overflow: auto;
}
.pagBox {
    width: 100%;
    float: left;
}
.pagNo {
    font-weight: 500;
    width: 10%;
    float: right;
    font-weight: 500;
    font-family: 'Barlow', sans-serif;
    margin-top: 5px;
}
.pagDgt {
    float: right;
    width: 60%;
    text-align: right;
}
.pagDgt .pagination {
    float: right;
}
.pagDgt .pagination li.active a {
    background: #1760ee !important;
}

.strRow {
    padding: 30px 10px 0px 30px;
}
.cardBody {
    height: 250px !important;
}
.stryCard{
    background: rgb(246, 248, 248) !important;
    height: 400px !important;
    font-weight: 600;
    font-size: 16px;
    border-radius: 6px;
    position: relative;
    color: #ffffff;
    border: 1px solid #fff;
    cursor: pointer;
}
.stryCard:hover{
     transform: scale(1.07);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}
.deletedStry {
    background: rgb(219, 223, 223) !important;
}
.wrWord {
     display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
}
.btnRst {
     background-color: rgb(252, 248, 248) !important;
    color: blue !important;
    padding: 5px 10px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 13px !important;
    border: 0.5px solid blue!important;
}
.srchBoxWPWebstory {
    width: 100%;
    float: left;
    /* padding-top: 10px; */
    /* padding-left: 50px; */
}
.srchBoxWPWebstory .react-select {
    width: 20%;
    float: left;
    margin-right: 1%;
}
.srchBoxWPWebstory #title {
    width: 30%;
    float: left;
    margin-right: 1%;
    font-size: 12px !important;
    font-family: 'Barlow', sans-serif;
    color: #333 !important;
    height: 40px;
}
.srchBoxWPWebstory .react-select .react-select__control {
    height: 30px !important;
    min-height: 30px;
}
.srchBoxWPWebstory .react-select .react-select__control .react-select__value-container{
    padding: 0px 10px;
}
.srchBoxWPWebstory .react-select__control .react-select__placeholder{
    font-size: 12px !important;
    font-family: 'Barlow', sans-serif;
    color: #333 !important;
}
.srchBoxWPWebstory .react-select .react-select__control .react-select__value-container .css-b8ldur-Input{
    margin: 0px !important;
}

.srchBoxWPWebstory .btn-smb{
    margin: 10px;
}
.srchBoxWPWebstory .btn-rst{
    margin: 10px;
    background: rgb(43, 43, 66);
}
