.formRow div {
  text-align: center;
  font-weight: 500;
  padding: 3px 3px;
  font-size: 15px;
  font-family: "Barlow", sans-serif;
}
.alignLeft {
  text-align: left !important;
  margin-left: 1px;
  margin-top: 0px;
}
