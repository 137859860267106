

.popupCloseBtn{
    margin-left: 396px !important;
    margin-top: -200px !important;
    position: absolute !important;
    font-size: 16px !important;
    font-weight: 600;

}
.popupCloseBtn:hover {
    cursor: pointer;
}

.sub-card{
    /* height: 100% !important; */
    border: 1px solid #ddd !important;
    box-shadow: 0px 0px 15px #eee !important;
    margin: 20px;
}

.line-space{
    margin-bottom: 30px;
}

.formCancelBtn {
    background-color: white  !important;
    color :  #1760EE !important;
    border: #1760EE !important;
    border-width: 1px !important;
    border-color: #1760EE !important;
    border-radius: 30px;
    font-size: 13px !important;
    font-weight: 500;
    font-family: 'Barlow';
    text-decoration: none !important;
    padding: 6px 10px !important;
    text-transform: capitalize !important;

    border-style: solid !important;
    border-color: #1760EE !important;
}

.formCancelBtn:hover
  {
    background-color: white  !important;
    color :  #1760EE !important;
    border: #1760EE !important;
    border-width: 1px !important;
    border-color: #1760EE !important;
    border-radius: 30px;
    font-size: 13px !important;
    text-decoration: none !important;
    padding: 6px 10px !important;
    text-transform: capitalize !important;

    border-style: solid !important;
    border-color: #1760EE !important;
  }