.app-container {
    position: relative;
    height: 100vh;
    z-index: 9999;
    padding: 100px;
}

.sliding-screen {
    padding: 20px 30px;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 35%; /* 1/3 of the screen width */
    background-color: white;
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.2); /* Optional: Add a box shadow for a visual effect */
    transition: transform 0.3s ease-in-out;
    overflow-y: auto; /* Allow scrolling if content exceeds the screen height */
}

.sliding-screen.closed {
    transform: translateX(100%);
}

.description {
    display: flex;
    gap: 15px;
}

.description button {
    display: inline-block;
    padding: 0px 10px;
    font-size: 9px;
    height: 28px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid #333;
    border-radius: 4px;
    color: #333;
    background-color: #fff;
    transition: background-color 0.3s, color 0.3s;
}

.chapter-tracks-table {
    border-collapse: collapse;
    width: 100%;
}

.chapter-tracks-table td {
    padding: 13px;
    font-size: 11px;
    border-bottom: 1px solid #ddd;
}

.chapter-tracks-table th {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
}

.chapter-tracks-table td:first-child {
    font-weight: 500;
}
