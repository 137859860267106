@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600&display=swap');

body{
    font-family: 'Barlow', sans-serif;
}
.dp-box{
    padding: 0px 10px 10px 10px;
}
.dp-box table th {
    text-align: left;
    font-weight: 500;
    color: #444;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 15px 7px;
    font-size: 15px;
    font-family: 'Barlow', sans-serif;
}
.dp-box .table .text-right {
    text-align: right !important;
}
#navbarDropdownMenuLink:after {
    display: none;
}
.user {
    background: #f1f1f1;
    margin-top: 0px !important;
    padding-top: 20px;
}
.logo:after {
    display: none;
}
.user:after {
    display: none;
}

.sidebar-wrapper .nav li.active a {
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li.active a i {
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li a i{
    font-size: 20px !important;
}
.sidebar-wrapper .nav li:hover a{
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li:hover a i{
    color: #0660f0 !important;
}
.info a span {
    text-transform: uppercase;
    font-family: 'Barlow';
    font-weight: 500;
}
.minBtn button{
    background: #d4d3d3;
}
.minBtn button i {
    color: #7c7e82;
}
.minBtn button:hover, .minBtn button:focus{
    background: #0d61f0 !important;
}
.minBtn button:hover i, .minBtn button:focus i {
    color: #fff !important;
}
.logo-mini{
    display: none !important;
}
.sidebar-mini .logo-mini{
    display: block !important;
}
.logo-normal img {
    width: 120px;
    padding-left: 10px;
}


.dp-box .btn-bg-rm {
    background: transparent !important;
    color: #777 !important;
    font-size: 20px !important;
    padding: 0px !important;
}
.dp-box .btn-bg-rm:hover{
    background: transparent !important;
    color: #1760ee !important;
}
.ctMng .btn-bg-rm:active, .ctMng .btn-bg-rm:active:hover, .ctMng .btn-bg-rm:active:focus {
    background: transparent !important;
    color: #1760ee !important;
}
.ctMng .btn-bg-rm:focus {
    background: transparent !important;
    color: #1760ee !important;
}
.dp-box .table .text-right .ftR {
    text-align: right;
    float: right;
}
.dp-box .table .text-right .grn:hover{
    color: rgb(3, 189, 59) !important;
}

.dp-box .table .text-right .rd:hover{
    color: rgb(233, 37, 2) !important;
}
.ctMng .spinner-border {
    width: 20px !important;
    height: 20px !important;
}
.ctMng .alert {
    background: transparent !important;
    color: rgb(3, 189, 59);
    padding: 0px;
    position: absolute;
    bottom: 0px;
    right: 70px;
    font-family: 'Barlow', sans-serif;
}
.ctMng .alrtrd{
    color: rgb(233, 37, 2) !important;
    font-family: 'Barlow', sans-serif;
}
.srchBox {
    width: 100%;
    float: left;
    padding-top: 10px;
}
.srchBox .react-select {
    width: 49%;
    float: left;
    margin-right: 1%;
}
.srchBox #searchtitle {
    width: 34%;
    float: left;
    margin-right: 1%;
    font-size: 12px !important;
    font-family: 'Barlow', sans-serif;
    color: #333 !important;
    height: 40px;
}
.srchBox #searchname {
    width: 34%;
    float: left;
    margin-right: 1%;
    font-size: 12px !important;
    font-family: 'Barlow', sans-serif;
    color: #333 !important;
    height: 40px;
}
.srchBox .react-select .react-select__control {
    height: 30px !important;
    min-height: 30px;
}
.srchBox .react-select .react-select__control .react-select__value-container{
    padding: 0px 10px;
}
.srchBox .react-select__control .react-select__placeholder{
    font-size: 12px !important;
    font-family: 'Barlow', sans-serif;
    color: #333 !important;
}
.srchBox .react-select .react-select__control .react-select__value-container .css-b8ldur-Input{
    margin: 0px !important;
}

.srchBox .btn-smb{
    margin: 10px;
}
.srchBox .btn-rst{
    margin: 10px;
    background: rgb(43, 43, 66);
}
.srchBox .btn-smb-txny{
    margin: 0px;
}
.srchBox .btn-rst-txny{
    margin: 0px;
    height: 100%;
    width: 100%;

    background-color: rgb(252, 248, 248) !important;
    color: blue !important;
    padding: 5px 10px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 16px !important;
    border: 0.5px solid blue !important;
    background: rgb(43, 43, 66);
}
.notActn a {
    display: inline-block;
    padding: 0px 4px;
    color: #777;
}
.notActn a:hover{
    color: #1760ee !important;
}
.notActn a i {
    font-size: 16px;
}
.notActn .dlIcn i {
    font-size: 17px;
}
.table-gray {
    background: #f7f7f7;
}
.nav-restr {
    text-align: center;
    color: #777;
    font-size: 15px;
}
.nav-restr:hover{
    color: #1760ee !important;
}
.dp-box .text-center{
    text-align: center !important;
}
.dp-box .table-responsive {
    overflow: auto;
}
.pagBox {
    width: 100%;
    float: left;
}
.txny-pagBox {
    width: 100%;
    float: left;
    padding-top: 10px !important;
}
.pagNo {
    font-weight: 500;
    width: 40%;
    float: left;
    font-weight: 500;
    font-family: 'Barlow', sans-serif;
}
.pagDgt {
    float: left;
    width: 60%;
    text-align: right;
}
.pagDgt .pagination {
    float: right;
}
.pagDgt .pagination li.active a {
    background: #1760ee !important;
}
.imgTmb img {
    width: 40px;
    height: auto;
}
#row_undefined a {
    color: #1760ee;
    text-decoration: none;
}