.track-header {
    display: flex;
    align-items: center;
    gap: 15px;
    color: white;
    font-size: 9px;
    background-color: #21252e;
    padding: 15px;
    margin: 0;
}

.back-button {
    font-size: 15px;
    cursor: pointer;
}
.track-header h2 {
    margin: 0;
}

.chapter-body {
    color: white;
    display: flex;
    background-color: #2f3542;
    padding: 10px 50px 50px 10px;
}

.track-label-input {
    font-size: 12px;
    height: 1.5rem;
    padding: 10px 5px;
    color: white;
    background-color: #21252e;
    margin-bottom: 10px;
}

.chapters-container {
    background-color: white;
    border-radius: 3px;
    margin-top: 10px;
}

.add-chapter-button {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 10px 20px;
    padding: 20px 10px 10px 10px;
    cursor: pointer;
    color: black;
    font-size: 12px;
    font-weight: 500;
    background-color: transparent;
    cursor: pointer;
    border: none; /* Remove the border */
    border-color: transparent;
}

.timer-input-container {
    background-color: #d2ebff;
    padding: 20px 10px 5px 10px;
    display: flex;
    flex-direction: column;
}

.timer-buttons-container {
    align-self: flex-end;
    padding-right: 10px;
    margin-bottom: 0;
    padding-bottom: 0;
}

.timer-buttons-container button {
    margin-left: 5px;
    font-size: 11px;
    font-weight: 600;
    border-radius: 3px;
    margin-bottom: 10px;
    color: #2f3542;
    padding: 3px 12px;
    background-color: transparent;
}
.timer-buttons-container .cancel-btn {
    border: none;
}

.timer-buttons-container .done-btn {
    border: 1px solid #2f3542;
}

.timer-buttons-container .cancel-btn:hover {
    border: none;
}

.timer-buttons-container .done-btn:hover {
    color: white;
    background-color: #2f3542;
}

.timer-input-box {
    display: flex;
    align-self: flex-start;
    width: 100%;
}

.timer-symbol-container {
    color: black;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.duration-message {
    padding: 0;
    margin: 0;
    font-size: 10px;
    color: red;
}

.timer-input {
    padding: 10px 5px;
    font-size: 10px;
    height: 1rem;
    width: 2rem;
    margin: 0 0px;
}

.timer-label-input {
    font-size: 10px;
    height: 1rem;
    padding: 10px 5px;
    margin: 0px 10px;
}

.chapter-list-header {
    margin-bottom: 10px;
}

.chapter-list-container {
    padding: 5px 15px;
}

.chapter-list {
    list-style-type: none;
    padding: 0;
}

.chapter-item {
    padding: 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chapter-item:hover {
    background-color: #d2ebff;
    cursor: pointer;
}

.chapter-start,
.chapter-label,
.chapter-gap,
.chapter-delete {
    margin-right: 10px;
}

.chapter-start {
    color: grey;
}
.chapter-label {
    color: black;
}

.chapter-delete {
    font-size: 10px;
    color: #bd0034;
}

.delete-icon {
    cursor: pointer;
}
.embed-video-container {
    background-color: #21252e;
    padding: 20px 20px;
    border: 1px solid black;
}

.embed-video-container div {
    display: flex;
    justify-content: flex-end;
}

.cancel-button,
.save-button {
    margin-left: 5px;
    font-size: 11px;
    font-weight: 600;
    border-radius: 3px;
    margin-bottom: 10px;
}

.cancel-button {
    padding: 5px 8px;
    cursor: pointer;
    background-color: #21252e;
    border: 1px solid white;
    color: white;
}

.save-button {
    padding: 5px 22px;
    cursor: pointer;
    background-color: #bd0034;
    border: 1px solid #bd0034;
    color: white;
}

.message {
    position: fixed;
    bottom: 40px;
    right: 40px;
    background-color: #1f9b85;
    color: white;
    padding: 15px 50px;
    border-radius: 5px;
}

.failed-message {
    position: fixed;
    bottom: 40px;
    right: 40px;
    background-color: #bd0034;
    color: white;
    padding: 15px 50px;
    border-radius: 5px;
}
