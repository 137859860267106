.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  font-weight: bold;
}

input, textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  resize: vertical;
}

.error {
  color: red;
  font-size: 0.8rem;
}

.submit-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0056b3;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  gap: 20px;
}

.pagination .page-number {
  font-size: 16px;
  font-weight: 600;
  color: #00a8ff;
  background: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s ease;
    margin-bottom: 1rem;
}

label {
    display: block;
    font-weight: bold;
}

.rdtPicker td{
    padding-left: 12px !important;
}


input,
textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

textarea {
    resize: vertical;
}

.error {
    color: red;
    font-size: 0.8rem;
}

.submit-btn {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-btn:hover {
    background-color: #0056b3;
}
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 20px;
}

.pagination .page-number {
    font-size: 16px;
    font-weight: 600;
    color: #00a8ff;
    background: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s ease;
}

.pagination .page-number:hover {
    color: #fff;
    background: #00a8ff;
}

.pagination .active {
    color: #fff;
    /* background: #00a8ff; */
}

.pagination .active:hover {
    color: #00a8ff;
    background: #fff;
}
.delete-modal {
    max-width: 400px;
    margin: 0 auto;
    border-radius: 8px;
}

.delete-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #dc3545;
    color: #fff;
    border: none;
}

.delete-modal-footer {
    justify-content: space-between;
    border-top: none;
}

.delete-button {
    background-color: #dc3545;
    border-color: #dc3545;
}

.delete-button:hover {
    background-color: #c82333;
    border-color: #bd2130;
}
.delete-modal-close {
    cursor: pointer;
    padding: 0.5rem;
    font-size: 1.25rem;
    color: #fff;
}

.cancil:hover {
    color: #007bff;
    cursor: pointer;
}

.tdCenter {
    text-align: center;
    vertical-align: middle;
}

.rm-border {
    border: none;
    background-color: transparent;
}
.navbar .navbar-toggler {
    border: none;
}

.check {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 18px;
    max-height: 100px;
    padding: 4px 10px;
    max-width: 400px;

    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}
.enlarge-icon {
    height: 1.5rem;
    width: 3rem;
}
.page {
    position: relative;
}
.b-text {
    position: relative;
    margin-top: 3rem;
}
.z-index {
    z-index: -1;
}

td .jwplayer-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 20px;
    max-height: 100px;
    padding: 4px 10px;
    max-width: 400px;

    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
td div .svg-inline--fa {
    width: 20px;
    height: 20px;
}
table > tbody > tr > td .icon_flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 3px;
}

.onHover:hover {
    color: black;
}
.default_color {
    color: black;
}
form button.btnBlue.form-button {
    color: white;
}

body {
    font-family: "Poppins", sans-serif;
    padding: 0;
    margin: 0;
    background: #f8f9fa;
}
a:hover {
    text-decoration: none;
}

header {
    width: 100%;
    float: left;
}
.header-top {
    width: 100%;
    float: left;
    padding-top: 20px;
}
.banner-sectn {
    width: 100%;
    float: left;
}

.header-top .navbar-brand img {
    width: 200px;
}
.navigationBox nav {
    padding: 0px;
}
.navigationBox nav .navbar-brand {
    padding: 0px;
}
#navbarText span {
    float: left;
    width: 100%;
}
#navbarText a img {
    width: 26px;
}
.overlay {
    background: #000;
    opacity: 0.7;
    position: absolute;
    width: 100%;
    height: 100%;
}
.upgrImg {
    width: 100%;
    float: left;
    padding-top: 30px;
}
.upgrImg img {
    width: 100%;
}
.banerHed {
    padding: 40px 0px 50px;
}
.banerHed h1 {
    color: #333;
    font-size: 42px;
    font-weight: 600;
    margin-bottom: 30px;
}
.banerHed p {
    color: #333;
    font-size: 15px;
}
.banerHed a {
    background: #0670fc;
    color: #fff;
    padding: 10px 20px;
    display: inline-block;
    font-size: 18px;
    border-radius: 5px;
}
/*.banerHed a:hover{
  transition: 0.5s ease;
}*/
.featCont.mt-5 h3 {
    font-size: 32px;
    margin-bottom: 10px;
}
.banerHed a:hover i {
    padding-right: 10px;
    transition: 0.5s ease;
}
.banerHed a i {
    transition: 0.5s ease;
}
.serviceBox {
    width: 100%;
    float: left;
    margin-bottom: 40px;
}
.servImg {
    width: 25%;
    float: left;
}
.servicCont h2 {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
}
.servicCont {
    width: calc(100% - 25%);
    float: left;
    padding-left: 20px;
}
.servicCont p {
    color: #ffffffd1;
}
.featureBox {
    width: 100%;
    float: left;
    /*padding: 100px 0px;*/
}
.featImg {
    width: 100%;
    float: left;
    position: relative;
    padding-top: 40px;
}
.featImg img {
    width: 100%;
}
.featCont p b {
    color: #f44336;
    font-weight: 500;
}
.featCont .blk {
    color: #333;
    font-weight: 600;
}
.featCont h4 {
    color: #4179fa;
    font-weight: 500;
    letter-spacing: 1px;
}
.featCont h3 {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 34px;
}
.featCont p {
    font-size: 15px;
    opacity: 0.7;
}
.featCont {
    margin-top: 10%;
}
.actNbtn {
    background: #0670fc;
    color: #fff;
    padding: 10px 20px;
    display: inline-block;
    font-size: 18px;
    border-radius: 5px;
}
.actNbtn:hover {
    color: #fff;
}
.featCont.lstFeat {
    margin-top: 12%;
}

@-webkit-keyframes bouncebounce {
    0% {
        bottom: 22px;
    }
    100% {
        bottom: 40px;
    }
}
@-moz-keyframes bouncebounce {
    0% {
        bottom: 22px;
    }
    100% {
        bottom: 40px;
    }
}
@-ms-keyframes bouncebounce {
    0% {
        bottom: 22px;
    }
    100% {
        bottom: 40px;
    }
}
@keyframes bouncebounce {
    0% {
        bottom: 22px;
    }
    100% {
        bottom: 40px;
    }
}
.bounce {
    position: inherit;
    -webkit-animation: bouncebounce 0.9s 1.5s infinite ease-out alternate;
    -moz-animation: bouncebounce 0.9s 1.5s infinite ease-out alternate;
    -ms-animation: bouncebounce 0.9s 1.5s infinite ease-out alternate;
    animation: bouncebounce 0.9s 1.5s infinite ease-out alternate;
}
.salientCont {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 30px;
}
.salientCont h3 {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 22px;
}
.salientCont p {
    width: 70%;
    margin-left: 15%;
    float: left;
    font-size: 17px;
    opacity: 0.7;
}
.salntFetCard {
    width: 100%;
    float: left;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    min-height: 280px;
    -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.salntFetCard:hover {
    transform: translate(0, -3px) !important;
    -webkit-transform: translate(0, -3px) !important;
    cursor: pointer;
}
.svgIcn {
    width: 100%;
    float: left;
}
.svgIcn img {
    width: 100%;
}
.salntFetCard h4 {
    font-size: 17px;
}
.salntFetCard p {
    font-size: 14px;
    opacity: 0.7;
}
.salientBox {
    width: 100%;
    float: left;
    background: #f1f1f1;
    margin: 50px 0px;
    padding-top: 50px;
}
.delRt {
    margin-bottom: 0px !important;
    font-weight: 500 !important;
    margin-top: 20px !important;
}
.qotaCrd h6 b {
    color: #f44336;
}
.pv6Img {
    width: 100%;
    float: left;
    margin: 30px 0px;
}
.pricLic del {
    padding-right: 20px;
    font-weight: 500;
}
.pv6Img img {
    width: 100%;
}
.pv6Img p {
    text-align: center;
    float: left;
    width: 100%;
    margin: 10px 0px 30px;
}
.priceCont {
    width: 100%;
    float: left;
}
.priceCont img {
    width: 80px;
    margin-bottom: 30px;
}
.priceCont h3 {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 22px;
}
.priceCont p {
    font-size: 17px;
    opacity: 0.7;
}
.priceCrd {
    padding: 40px 30px;
    background: #fff;
    border: 2px solid #e9ecef;
    border-radius: 5px;
    width: 100%;
    float: left;
}
.priceCrd ul {
    list-style-type: none;
    padding-left: 0px;
    float: left;
    width: 100%;
}
.priceCrd ul li {
    display: block;
    margin-bottom: 15px;
    font-weight: 400;
    color: #616467;
}
.priceCrd ul li svg {
    width: 27px;
}
.licFee {
    background: #0771fc;
    color: #fff;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    font-weight: 500;
    margin-bottom: 20px;
    display: table;
}
.pricLic {
    font-weight: 700;
    font-size: 29px;
}
.pricLic sub {
    position: relative;
    top: 1px;
    font-weight: 400;
    font-size: 16px;
}
.pricLic {
    font-weight: 700;
    font-size: 29px;
    width: 100%;
    float: left;
    margin-bottom: 30px;
}

.feeBox {
    float: left;
    width: 100%;
    margin-bottom: 60px;
}
.qotaCrd {
    width: 17%;
    float: left;
    margin: 0px 15px;
    box-sizing: border-box;
    background: #fff;
    padding: 20px;
    border: 2px solid #e9ecef;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
}
.pQota {
    background: #0771fc;
    color: #fff;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 15px;
}
.priceCont.qotFe p {
    margin-bottom: 3px;
    font-size: 15px;
}
.priceCont.qotFe {
    margin-bottom: 40px;
}
.QotaRte {
    font-weight: 600;
    font-size: 19px;
    margin: 10px 0px;
    float: left;
    width: 100%;
    margin-top: 0px;
}
.qotaCrd h6 {
    float: left;
    font-size: 15px;
    opacity: 0.8;
}
.volBox {
    width: 100%;
    float: left;
    font-size: 14px;
    margin-top: 30px;
    font-weight: 400;
}
.volBox span svg {
    width: 20px;
}
.videoBox {
    width: 100%;
    float: left;
}
.foterLogo img {
    width: 200px;
}
.foterLogo {
    width: 100%;
    float: left;
}

.fotrLink a img {
    width: 30px;
}
.fotrLink {
    width: 100%;
    float: left;
}
.foterLogo img {
    width: 180px;
    margin-bottom: 30px;
}
.videoSctn h2 {
    color: #fff;
}

.videoSctn {
    width: 100%;
    float: left;
    padding-top: 14%;
}
.videoSctn svg {
    width: 30px;
}
#background-video {
    width: 100%;
}
.videoBox .overlay {
    top: 0;
}

.videoSctn a {
    background: #fff;
    padding: 10px 20px;
    display: inline-block;
    margin-top: 20px;
    border-radius: 5px;
    color: #333;
    z-index: 9999 !important;
    font-weight: 500;
}

.pubBtn a {
    background: #0670fc;
    color: #fff;
    font-weight: 400;
    padding: 7px 20px;
    border-radius: 5px;
    font-size: 15px;
}
.dn-s {
    display: block;
}
.dn-l {
    display: none;
}

.helpTxt {
    font-size: 15px;
    width: 100%;
    float: left;
    margin-bottom: 40px;
}
#navbarText {
    float: right;
    width: 100%;
    text-align: right;
}
.fotbtm {
    width: 100%;
    float: left;
    height: 50px;
    background: #0a70fa;
}
.recBox {
    background: #f44336;
    position: absolute;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 5px 5px 0px 0px;
    right: 0px;
    top: -29px;
    font-weight: 500;
    letter-spacing: 1px;
}

/* jw player */

.jw-Main-dashboard .navbar-nav {
    justify-content: left;
    width: 100%;
    background: #0660f0;
}
.content.jw-content {
    min-height: unset !important;
    padding-bottom: 0px !important;
}
.jw-Main-dashboard .navbar-nav li a {
    line-height: initial !important;
    padding: 14px 10px 14px !important;
    font-weight: 500;
    color: #fff !important;
}
.jw-Main-dashboard .navbar-nav li a.active {
    color: #fff !important;
    background: #064ec1;
}
.content.jw-content .card {
    margin-bottom: 0px !important;
    border-radius: 12px 12px 0px 0px !important;
}
.content.be-jwCont {
    margin-top: 0px !important;
}
.content.be-jwCont .card .card-body {
    padding-top: 0px !important;
}
.content.be-jwCont .card {
    border-radius: 0px 0px 12px 12px !important;
}
.jw-Main-dashboard {
    margin-bottom: 0px !important;
}
.srchTitl {
    width: 40%;
    margin: 0px 5px;
    position: relative;
    top: 2px;
}
.bg-gry {
    background: #66615b !important;
}
.btn-smb-grn {
    background: #05990b !important;
}
.btn-rst-gry {
    color: #66617f !important;
    background: transparent !important;
    border: 2px solid #66617f !important;
    padding: 9px 20px !important;
}
.btn-smb-ad.friht.btn.btn-info {
    float: right;
}
.pagJw {
    width: 100%;
    float: left;
}
.dp-box.videoContnr {
    padding: 0px !important;
    width: 100%;
    float: left;
}
.tBdata-list {
    width: 100%;
    float: left;
    padding: 0px 10px;
}
.pagJw .pagination {
    margin-top: 10px;
    float: left;
    text-align: right;
    width: 100%;
    justify-content: right;
}
.pagJw b {
    float: right;
    padding: 20px 10px 10px;
}
td.d-flex.actnTabl {
    /* flex-direction: unset !important; */
    /* margin-top: 33px; */
    flex-flow: wrap !important;
    display: table-cell !important;
    column-count: initial !important;
}
td.d-flex.actnTabl svg {
    margin: 0px 5px !important;
    float: left;
}
.tBdata-list table tbody tr {
    border-bottom: 1px solid #ccc;
}

.tBdata-list table tbody tr td {
    border-bottom: none !important;
}
.jwplayer-title {
    text-transform: capitalize;
}
.btn-smb-ad.friht.btn.btn-info:hover {
    background: #1847a2 !important;
}

/* --- modal start --- */

.upLVid {
    width: 100%;
    float: left;
    position: relative;
    padding-top: 10px;
}
.upLVid h4 {
    margin-top: 0px;
    font-family: "Barlow" !important;
    font-size: 19px;
    font-weight: 500;
    width: 100%;
    float: left;
}
.upLVid .form-label {
    width: 100% !important;
}
.upLVid .form-label input {
    width: 100% !important;
    margin-top: 6px;
}
.upLVid .form-label textarea {
    margin-top: 6px;
}
.manage-chapter-btn-container {
    border: 1px solid #9f9b96;
    border-radius: 2px;
    padding: 10px 0 0 10px;
    display: flex;
    margin-top: 3px;
    flex-direction: column;
    align-items: start;
}

.manage-chapter-btn {
    border-radius: 3px;
    border: 1px solid grey;
    padding: 2px 10px;
    background-color: #f1f1f1;
    color: black;
}
.manage-chapter-btn-container p {
    color: #9f9b96;
    padding: 0;
    font-size: 12px;
    margin-bottom: 3px;
}

.below-input-text {
    display: flex;
    justify-content: space-between;
}
.cancil {
    position: absolute;
    right: 0px;
    top: 5px;
    background: #efefef;
    padding: 1px 10px;
    height: 30px;
    border-radius: 50%;
    font-size: 17px;
}
.upLVid button {
    float: right;
}
.copyCrd {
    width: 100%;
    float: left;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 5px;
}
.modal-body {
    padding: 20px 30px !important;
}
.upLVid.del-Crd input {
    height: 40px;
}
.actnMdlCrd {
    width: 100%;
    float: left;
    text-align: right;
    margin-top: 20px;
}
/* modal css end */

.viGraph-cardBox {
    width: 100%;
    float: left;
}
.videoActnTab {
    width: 100%;
    float: left;
    margin-top: 20px;
    /* background: #eee;
  padding: 10px; */
}
.inpt#fromDate {
    height: 39px;
}
.slctDy {
    width: 100%;
    float: left;
    margin-top: 25px;
    font-family: "Barlow";
}
.actBx {
    width: 100%;
    float: left;
    margin-top: 15px;
}
.btn.btn-smb-tab {
    background: #116bf0;
    color: #fff !important;
}
.btn.btn-smb-tab:focus,
.btn.btn-smb-tab:hover {
    background: #116bf0 !important;
}
.btn.btn-gry-tab {
    background: #eee;
    border-left: 1px solid #ccc;
}
.viGraph-cardBox .grCard {
    width: 100%;
    float: left;
    margin-top: 30px;
}
.loDer {
    width: 100%;
    float: left;
    text-align: center;
    padding: 10vh;
}
.btn-sbm {
    background: #116bf0;
    color: #fff !important;
}
.videoActnTab button {
    margin: 0px;
    margin-left: 0px !important;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    #navbarText {
        width: auto !important;
        float: left;
        text-align: left;
        margin-top: 20px;
    }
    #navbarText span {
        margin-top: 10px;
    }
    .featImg img.bounce {
        transition: none !important;
        animation: none;
    }
    .qotaCrd {
        width: 100%;
        margin-bottom: 10px;
    }
    .navigationBox nav a img {
        width: 120px;
    }
    .navigationBox .navbar {
        padding-left: 0px;
        padding-right: 0px;
    }
    .banerHed h1 {
        font-size: 24px;
    }
    .featImg img {
        width: 90%;
    }
    .featImg {
        text-align: center;
        padding-top: 0px;
    }
    .banerHed h3 {
        color: #fff;
        font-size: 16px;
        margin-top: 19px;
        margin-bottom: 20px;
        font-weight: 300;
        opacity: 0.8;
    }
    .banerHed a {
        padding: 9px 15px;
        font-size: 15px;
    }
    .servImg img {
        width: 100%;
    }
    .servImg {
        width: 20%;
    }
    .servicCont {
        width: calc(100% - 20%);
    }
    .servicCont h2 {
        font-size: 18px;
        margin-bottom: 3px;
    }
    .servicCont p {
        color: #ffffffd1;
        font-size: 15px;
        line-height: initial;
    }
    .serviceBox {
        margin-bottom: 10px;
    }
    .banerHed {
        padding: 0px;
    }
    .featureBox .row {
        margin: 0px 0px;
    }
    .featCont h3 {
        font-size: 20px;
    }
    .featCont p {
        font-size: 15px;
    }
    .dn-s {
        display: none;
    }
    .dn-l {
        display: block;
    }
    .salientBox {
        width: 100%;
        float: left;
        margin-top: 20px;
        padding-top: 30px;
    }
    .actNbtn {
        font-size: 14px;
    }
    .volBox {
        margin-top: 10px;
    }
    .salientCont p {
        width: 100%;
        margin-left: 0;
        font-size: 15px;
        opacity: 0.7;
    }
    .pv6Img {
        margin: 30px 0px 10px;
    }
    .priceCont h3 {
        font-size: 19px;
    }
    .priceCont p {
        font-size: 15px;
    }
    .pricLic {
        margin-bottom: 19px;
    }
    .priceCrd ul li {
        font-size: 15px;
    }
    .recBox {
        border-radius: 0px 0px 0px 5px;
        top: 0;
    }
    .salntFetCard {
        margin-bottom: 0px;
    }

    .feeBox {
        margin-bottom: 10px;
    }
    .videoSctn {
        padding-top: 0px;
    }
    .videoSctn h2 {
        color: #fff;
        font-size: 17px;
    }

    .videoSctn a {
        margin-top: 11px;
    }
    .featCont {
        margin-top: 2%;
    }
    .featCont.mt-5 h3 {
        font-size: 20px;
    }
    .featCont h5 {
        font-size: 16px;
    }
}

/* SlidingChapterScreen */

.chapter-tracks-table {
}

.no-chapters-message {
    text-align: center;
    color: black;
    height: 70vh;
}

.chapter-track-list-label {
    cursor: pointer;
}
.testing{
  position: relative;
 
}

.chapter-track-list:hover {
    background-color: #d2ebff;
}

.delete-icon {
    color: #bd0034;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
        0,
        0,
        0,
        0.2
    ); /* Semi-transparent black background */
}
