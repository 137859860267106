.dp-box .text-center{
    text-align: center !important;
}
.dp-box table tr td {
    text-align: left !important;
    font-size: 14.2px;
    color: #666;
    font-family: 'Barlow', sans-serif;
}
.mrgin {
    margin-top: 10px !important;
    text-align: left !important;
}

.detailsTable {
    text-align: left;
    margin-top:  15px !important;
}
.detailsTable td {
padding-top: 10px !important;
  padding-bottom: 10px !important;
  
}
.closeBtn {
    background-color: #ffffff !important;
    border: 1px solid grey;
    color: rgb(3, 3, 3) !important;
    height: 30px !important;
    width: 30px !important;
    padding: 5px 2px 20px !important;
    text-align: center ! important;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 50% !important;
}
.closeBtn:hover {
    background-color: #ffffff !important;
    color: black !important;
    text-decoration: none !important;
}

.viewBtnCss {
    background-color: rgb(252, 248, 248) !important;
    color: blue !important;
    padding: 5px 10px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 13px !important;
    border: 0.5px solid blue!important;
}
.btn-rst {
     background-color: rgb(252, 248, 248) !important;
    color: blue !important;
    padding: 5px 10px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 13px !important;
    border: 0.5px solid blue!important;
}

.icon-bar {
  width: 100% !important;
  background-color: #555 !important;
  /* overflow: auto !important; */
}

.icon-bar a {
  float: left !important;
  width: 20% !important;
  text-align: center !important;
  padding: 12px 0 !important;
  transition: all 0.3s ease !important;
  color: white !important;
  font-size: 36px !important;
}

.icon-bar a:hover {
  background-color: #000 !important; 
}
.actionItem {
     display:flex !important;
     
}

.calenderCSS .text-center{
    text-align: center !important;
}
.calenderCSS table tr td {
    text-align: center !important;
    font-size: 14.2px;
    color: #666;
    font-family: 'Barlow', sans-serif;
}