@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600&display=swap');

body{
    font-family: 'Barlow', sans-serif;
}
.rw-number-notification {
    position: absolute;
    top: -12px;
    right: -8px;
    padding: 5px 5px 3px 4px;
    background-color: red;
    color: white;
    font-family: arial;
    font-weight: bold;
    font-size: 10px;
    border-radius: 8px;
    /* box-shadow: 1px 1px 1px silver; */
}
.unreadAlert {
    border-left: 6px solid #afacac;
    background-color: #cec8c8;
    /* box-shadow: 1px 1px 1px silver; */
    background: #e1e1e1 !important;
}
 .readAlert {
     border-left:6px solid #d9d8d8;
    background-color:#ffffff;
    /* box-shadow: 1px 1px 1px silver; */
} 
.alertCard {
    cursor: pointer;
    border-radius: 10px;
    word-break: break-word;
}

.notAlrtDv:before, .notAlrtDv:after {
    display: none !important;
}
.alrtBl .notAlrtDv {
    right: -21px;
    margin-top: 0px;
    left: unset !important;
    position: fixed !important;
    z-index: 999999 !important;
    bottom: 0px !important;
    overflow-y: scroll;
    height: 100vh;
}
.overlayAlert {
    background: #ffffffbd;
    width: 100%;
    height: 100vh;
    z-index: 999;
    position: fixed;
    transform: blur;
    filter: contrast(0.5);
}
.alertActive{
    display: block !important;
}
.drop {
    width: 400px;
    /* overflow-y: scroll;
    height: 100vh; */
}
.notAlrtDv .drop .card {
    margin: 10px 10px 0px;
    border-radius: 10px;
    overflow: hidden;
}
.noAlertMsg {
    text-align: center;
}
.clsPopAlrt i {
    color: #fff;
}

.clsPopAlrt {
    position: absolute;
    right: 12px;
    top: 5px;
}
.clrAllAlert{
    position: absolute;
    right: 40px;
    top: -12px;
    height: 35px;
}
.clrAllAlert .spinner-border{
   width: 1rem;
   height: 1rem; 
}
.notiflink {
    /* position: absolute;
    left: 0px;
     */
     color:rgb(11, 4, 19)
}

.notAlrtDv .drop .card .unreadAlert .aH6 {
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 15px;
}
.notAlrtDv .drop .card .unreadAlert p {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 500;
}
.alrtBl.dropdown.show .dropdown-menu[x-placement="bottom-start"]{
    top: -51px !important;
}
.alertLoader {
    position: absolute;
    right: 50%;
    top: 50px;
}
.btn-smb {
    font-size: 13px !important;
    font-weight: 500;
    font-family: 'Barlow';
    padding: 6px 10px !important;
    text-transform: capitalize !important;
    background: #1760ee!important;
}

#scrollStyle::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(71, 71, 71, 0.3);
	background-color: #F5F5F5;
}

#scrollStyle::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#scrollStyle::-webkit-scrollbar-thumb
{
	background-color: #afacac
}
.drop .alertCard:last-child {
    margin-bottom: 30px;
}
.drop .card {
    box-shadow: none;
}
.readAlert.card-body {
    background: #f5f5f5 !important;
    border-bottom: 1px solid #cfcfcf !important;
}
.alertCard p {
    line-height: normal !important;
    word-break: break-word;
    font-size: 14px !important;
}

.sidebar .nav p, .off-canvas-sidebar .nav p{
    color: #000 !important;
}
.sidebar .nav li a i {
    color: #000 !important;
}
.btnBlue:hover{
    background: #124fc7 !important;
}
.btnBlue.btn-secondary:hover{
    background: #124fc7 !important;
}
.fUpldv {
    float: left;
    width: 100%;
    text-align: left;
}
.fUpldv label{
    display: block;
    float: left;
    width: 100%;
}
.btn-smb:hover{
    background: #124fc7 !important;
}
.btn-smb.btn-secondary:hover{
    background: #124fc7 !important;
}

.enblBtn {
    font-size: 13px !important;
    text-transform: none !important;
    padding: 6px 10px !important;
    font-weight: 500 !important;
    font-family: 'Barlow';
    background: #4caf50 !important;
}
.enblBtn.btn-secondary:hover{
    background: #2d8730 !important;
}
.rightNavMenu a {
    color : #66615B !important
}