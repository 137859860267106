.mainCard {
  margin-top: -10px !important;
  width: auto;
}

.androidCardImage {
  width: 80% !important;
  align-items: center !important;
  height: 282px !important;
  margin: 0px auto;
}

.iosCardImage {
  box-shadow: none !important;
  width: 80% !important;
  align-items: center !important;
  margin: 0px auto;
  height: 281px;
}

.phonAp {
  box-shadow: none !important;
}

.actionItem a i {
  color: #333;
  font-size: 21px;
}

.androidImage {
  /* background-color: rgb(14, 150, 14); */
  height: 150px !important;
  width: 150px !important;

  margin-top: 50px !important;
  /* margin-left: 50px !important; */
}

.iosImage {
  /* background-color: grey; */
  margin-top: 50px !important;
  height: 150px !important;
  width: 150px !important;

}

.appTypeLabel {
  margin-top: -20px !important;
  margin-left: 15px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}


.androidFormCard {
  box-shadow: none !important;
  height: 100%;
  /* overflow-y: scroll; */
}

.iOSFormCard {
  box-shadow: none !important;
  height: 100%;
}

.gridCont {
  display: grid !important;
}


.manageContainer {
  display: flex;
}

.cardCol {
  /* padding-left: 5px;
    padding-right: 50px; */
}

.appTypeImg {
  /* padding-left: 20px; */
  width: 250px;
  height: 250px
}

.imgDiv {

  background-color: black;
}


.iconlst {
  line-height: initial;
  text-align: left;
  margin-bottom: 4px;
}

/* .actionItem {
       display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  } */
.actionItem {
  display: flex !important;
  justify-content: center;
  text-align: center;
  width: 100%;
  transition: all .2s ease;
}

.actionItemdiv {
  width: auto;
  display: flex !important;
  float: left;
}

.actionItem a {
  background: #eee;
  height: min-content;
  padding-top: 8px;
  cursor: pointer;
  margin: 0px 4px;
  border-radius: 17%;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  border: 1px solid #dbdbdb;
}

.actionItem a:hover {
  background: #1760ee !important;
  border: 1px solid #1760ee !important;
}

.actionItem a:hover i {
  color: #fff !important
}

.phonAp h5 {
  color: #1760ee;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  display: none;
}

.phonAp:hover {
  cursor: pointer;
}

/*.phonAp:hover .actionItem {
      display: inline-block !important;
      transition: all 2s ease
  
  }*/
.cardCol {
  padding-right: 0px;
}

.pad {
  padding: 7px 10px !important;
}

.btnicon {
  background-color: rgb(7, 126, 238);
  /* border : solid !important ;
      font-size: 8px !important;
      border-radius: 15px !important;
      width: auto;
      cursor: pointer;
      font-size: 10px !important;
      border-radius: 15px !important; */

  font-size: 13px !important;
  font-weight: 500;
  font-family: 'Barlow';
  padding: 7px 10px !important;
  text-transform: capitalize !important;
  cursor: pointer;
  width: 140px;
  border-radius: 10px !important;
}

.btnsmicon {
  font-size: 10px !important;
  border-radius: 15px !important;
  cursor: pointer;
}

.btnsmiconred {
  font-size: 10px !important;
  border-radius: 15px !important;

  background-color: rgb(240, 88, 88) !important;
  font-size: 13px !important;
  font-weight: 500;
  font-family: 'Barlow';
  padding: 7px 10px !important;
  text-transform: capitalize !important;
  cursor: pointer;
}

.tricon {
  background-color: rgb(226, 223, 223);
  height: min-content !important;
  border-radius: 33px !important;
}

.tricon td {
  padding: 3px 11px !important;
  align-items: center !important;
}

.btnGry {
  background: #66615B;
  font-size: 14px;
  text-transform: unset !important;
  font-weight: 500;
  font-family: 'Barlow';
}

.notEnbl {
  width: 100%;
  float: left;
  margin: 4% 0 5%;
  padding: 0px 30px;
  text-align: center;
}

.hedErr {
  width: 100%;
  float: left;
  text-align: center;
}

.cardHeRor {
  font-size: 20px;
  font-weight: 500;
  color: #4f4f4f;
}

.hedErr img {
  width: 50%;
  margin-bottom: 30px;
}